import { RoomContentWrapper } from '@ysura/common';

import { WaitingRoomCallInfo } from '@/views/Interaction/Common';
import { CallStatusBlock } from '@/views/Interaction/Telephone/CallStatus';

type TelephoneWaitingRoomContentProps = {
  handleStartInteraction: VoidFunction;
};

export const TelephoneWaitingRoomContent = ({
  handleStartInteraction,
}: TelephoneWaitingRoomContentProps) => {
  return (
    <RoomContentWrapper>
      <CallStatusBlock />

      <WaitingRoomCallInfo
        handleStartInteraction={handleStartInteraction}
        checkPermission={false}
      />
    </RoomContentWrapper>
  );
};
