import { Loader } from '@ysura/common';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';

import {
  useInteraction,
  useMediaDevices,
  usePermissionCheck,
  usePermissionPrompt,
} from '@/hooks';
import {
  Feedback,
  InteractionAnimate,
  WaitingRoomAnimate,
} from '@/views/Interaction/Common';

import { Interaction } from './Interaction';
import { RemoteWaitingRoom } from './WaitingRoom';

export const RemoteInteraction = () => {
  const {
    roomId,
    attendee,
    arePermissionsChecked,
    isStateServerInitialized,
    cameraPermissionState,
    microphonePermissionState,
    initializeStateServer,
    leaveInteraction,
    broadcastWaitingRoomToInteraction,
    getInteractionStep,
    createOrUpdateInteractionStep,
    heartbeat,
  } = useInteraction();
  const { requestPermissions } = usePermissionPrompt();
  const { loadMediaDevicesIntoState } = useMediaDevices();
  const { checkPermissions } = usePermissionCheck();

  const currentStep = getInteractionStep(roomId);
  const pendingPermissionsState =
    cameraPermissionState === 'pending' &&
    microphonePermissionState === 'pending';

  useEffect(() => {
    // Initialize state-server connection
    if (
      roomId &&
      (currentStep === 'waiting-room' || currentStep === 'interaction')
    ) {
      initializeStateServer({
        roomId,
        interactionStep: currentStep,
      });
    }
  }, [roomId, currentStep, initializeStateServer]);

  useEffect(() => {
    /**
     * If interaction step data for a roomId does not exist,
     * create a new one with default step value (waiting-room).
     */
    if (!currentStep && roomId) {
      createOrUpdateInteractionStep(roomId, 'waiting-room');
    }
  }, [currentStep, roomId, createOrUpdateInteractionStep]);

  useEffect(() => {
    /**
     * >> Check permissions <<
     * (runs only if the page was refreshed)
     */
    if (!arePermissionsChecked) {
      checkPermissions();
    }
  }, [arePermissionsChecked, checkPermissions]);

  useEffect(() => {
    /**
     * >> Request permissions <<
     */

    if (arePermissionsChecked || pendingPermissionsState) {
      requestPermissions(true, true);
    }
  }, [arePermissionsChecked, pendingPermissionsState, requestPermissions]);

  useEffect(() => {
    /**
     * Loads a list of media devices (i.e. available cameras & mics)
     * and saves them in the state managed by useInteraction context
     */
    loadMediaDevicesIntoState();
  }, [loadMediaDevicesIntoState]);

  useEffect(() => {
    let heartbeatInterval: NodeJS.Timeout;
    if (
      isStateServerInitialized &&
      attendee?.personOid &&
      attendee?.role &&
      (currentStep === 'interaction' || currentStep === 'waiting-room')
    ) {
      heartbeatInterval = setInterval(() => {
        heartbeat();
      }, 5000);
    }

    return () => {
      clearInterval(heartbeatInterval);
    };
  }, [
    attendee?.personOid,
    attendee?.role,
    currentStep,
    heartbeat,
    isStateServerInitialized,
  ]);

  const handleStartInteraction = () => {
    createOrUpdateInteractionStep(roomId, 'interaction');

    broadcastWaitingRoomToInteraction?.();
  };

  const handleLeaveInteraction = () => {
    createOrUpdateInteractionStep(roomId, 'feedback');
    leaveInteraction();
  };

  if (currentStep === 'feedback') {
    return <Feedback roomId={roomId} />;
  }

  return isStateServerInitialized ? (
    <AnimatePresence exitBeforeEnter>
      {currentStep === 'waiting-room' && (
        <WaitingRoomAnimate key="-remote-waiting-room-animate">
          <RemoteWaitingRoom handleStartInteraction={handleStartInteraction} />
        </WaitingRoomAnimate>
      )}
      {currentStep === 'interaction' && (
        <InteractionAnimate key="remote-interaction-room-animate">
          <Interaction handleLeaveInteraction={handleLeaveInteraction} />
        </InteractionAnimate>
      )}
    </AnimatePresence>
  ) : (
    <Loader />
  );
};
