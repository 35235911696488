import {
  ConsentViewerPayload,
  ReactProps,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { createContext, FC, useContext, useMemo, useState } from 'react';

import {
  DEFAULT_FN,
  SharingMedia,
  UseProcessState,
} from './useInteractionTypes';

export const useProcessStateInitialState: UseProcessState = {
  isAnyProcessShared: false,
  isFormSubmitting: false,
  isMediaShared: false,
  sharingConsent: null,
  sharingSampleRequest: null,
  sharingMedia: null,

  setIsFormSubmitting: DEFAULT_FN,
  setSharingConsent: DEFAULT_FN,
  setSharingSampleRequest: DEFAULT_FN,
  setSharingMedia: DEFAULT_FN,

  isSharingInFullScreen: false,
  setIsSharingInFullScreen: DEFAULT_FN,
};

const ProcessStateContext = createContext<UseProcessState>(
  useProcessStateInitialState
);

export const ProcessStateContextProvider: FC<ReactProps> = (props) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [sharingConsent, setSharingConsent] =
    useState<ConsentViewerPayload | null>(null);
  const [sharingSampleRequest, setSharingSampleRequest] =
    useState<SampleRequestViewerPayload | null>(null);
  const [sharingMedia, setSharingMedia] = useState<SharingMedia | null>(null);

  const [isSharingInFullScreen, setIsSharingInFullScreen] = useState(false);

  const values = useMemo(
    () => ({
      isAnyProcessShared:
        !!sharingMedia || !!sharingConsent || !!sharingSampleRequest,
      isFormSubmitting,
      isMediaShared: !!sharingMedia,
      sharingConsent,
      sharingSampleRequest,
      sharingMedia,

      setIsFormSubmitting,
      setSharingConsent,
      setSharingSampleRequest,
      setSharingMedia,

      isSharingInFullScreen,
      setIsSharingInFullScreen,
    }),
    [
      isFormSubmitting,
      isSharingInFullScreen,
      sharingConsent,
      sharingMedia,
      sharingSampleRequest,
    ]
  );

  return <ProcessStateContext.Provider value={values} {...props} />;
};

export const useProcessState = (): UseProcessState => {
  return useContext(ProcessStateContext);
};
