import { RoomHeaderWrapper } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';
import { useInteraction } from '@/hooks';

export const InteractionHeader = () => {
  const { t } = useTranslation();
  // this value will contain the full name and the person's pre/postfix
  const { attendee } = useInteraction();

  return (
    <RoomHeaderWrapper>
      <PageHeader
        title={t('pages.room.welcome', {
          name: attendee?.displayName,
        })}
      />
    </RoomHeaderWrapper>
  );
};
