import {
  ConsentViewerPayload,
  RejectConsentPayload,
  RevokeConsentPayload,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RejectConsentDialog,
  RevokeConsentDialog,
} from '@/components/RemoteInteraction/ConsentActions';
import { useInteraction, useNotification } from '@/hooks';

export const ConsentActionManager = () => {
  const { toast } = useNotification();
  const { t } = useTranslation();

  const {
    isAnyContentShared,
    isFormSubmitting,
    setSharingConsent,
    onConsentFormOpened,
    onConsentFormValueChanged,
    onConsentClosed,
    onOrganizerConsentRejected,
    onOrganizerConsentRevoked,
    setIsFormSubmitting,
    broadcastRejectConsentConfirm,
    broadcastRevokeConsentConfirm,
    broadcastConsentClose,
  } = useInteraction();

  const [rejectConsent, setRejectConsent] = useState<
    RejectConsentPayload | undefined
  >(undefined);
  const [revokeConsent, setRevokeConsent] = useState<
    RevokeConsentPayload | undefined
  >(undefined);

  useEffect(() => {
    const handleShowConsentForm = (data: ConsentViewerPayload) => {
      setSharingConsent(data);
    };

    const handleShowRejectConsentConfirmation = (
      data: RejectConsentPayload
    ) => {
      setRejectConsent(data);
    };

    const handleShowRevokeConsentConfirmation = (
      data: RevokeConsentPayload
    ) => {
      setRevokeConsent(data);
    };

    const handleHideConsent = () => {
      if (!isAnyContentShared) {
        if (rejectConsent) {
          setRejectConsent(undefined);

          return toast?.({
            message: t('notifications.consentRejectCanceled'),
            type: 'warning',
          });
        }

        if (revokeConsent) {
          setRevokeConsent(undefined);

          return toast?.({
            message: t('notifications.consentRevokeCanceled'),
            type: 'warning',
          });
        }
      }

      if (isFormSubmitting) {
        toast?.({
          message: t('notifications.consentFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('notifications.consentFormCancelled'),
          type: 'warning',
        });
      }

      setIsFormSubmitting(false);
      setSharingConsent(null);
    };

    const unsubscribeOnConsentClose = onConsentClosed?.(handleHideConsent);

    const unsubscribeOnOrganizerConsentRejected = onOrganizerConsentRejected?.(
      handleShowRejectConsentConfirmation
    );

    const unsubscribeOnOrganizerConsentRevoked = onOrganizerConsentRevoked?.(
      handleShowRevokeConsentConfirmation
    );

    const unsubscribeOnConsentFormOpen = onConsentFormOpened?.(
      handleShowConsentForm
    );
    const unsubscribeOnConsentFormChange = onConsentFormValueChanged?.(
      handleShowConsentForm
    );

    return () => {
      if (unsubscribeOnConsentClose) {
        unsubscribeOnConsentClose();
      }

      if (unsubscribeOnOrganizerConsentRejected) {
        unsubscribeOnOrganizerConsentRejected();
      }

      if (unsubscribeOnOrganizerConsentRevoked) {
        unsubscribeOnOrganizerConsentRevoked();
      }

      if (unsubscribeOnConsentFormOpen) {
        unsubscribeOnConsentFormOpen();
      }
      if (unsubscribeOnConsentClose) {
        unsubscribeOnConsentClose();
      }
      if (unsubscribeOnConsentFormChange) {
        unsubscribeOnConsentFormChange();
      }
    };
  }, [
    onConsentClosed,
    toast,
    t,
    onOrganizerConsentRejected,
    onOrganizerConsentRevoked,
    rejectConsent,
    revokeConsent,
    onConsentFormOpened,
    onConsentFormValueChanged,
    setSharingConsent,
    isAnyContentShared,
    isFormSubmitting,
    setIsFormSubmitting,
  ]);

  const handleRejectConfirmation = () => {
    if (rejectConsent) {
      broadcastRejectConsentConfirm?.(rejectConsent);
    }
    setRejectConsent(undefined);
  };

  const handleCancelReject = () => {
    if (rejectConsent) {
      broadcastConsentClose?.({
        id: rejectConsent.consentType.id,
      });
      setRejectConsent(undefined);
    }
  };

  const handleCancelRevoke = () => {
    if (revokeConsent) {
      broadcastConsentClose?.({
        id: revokeConsent.id ?? rejectConsent?.consentType.id,
      });
      setRevokeConsent(undefined);
    }
  };

  const handleRevokeConfirmation = () => {
    if (revokeConsent) {
      broadcastRevokeConsentConfirm?.(revokeConsent);
    }
    setRevokeConsent(undefined);
  };

  return (
    <>
      {rejectConsent && (
        <RejectConsentDialog
          consentName={rejectConsent.consentType.name ?? ''}
          onConfirmDialog={handleRejectConfirmation}
          onCancelDialog={handleCancelReject}
        />
      )}

      {revokeConsent && (
        <RevokeConsentDialog
          consentName={revokeConsent?.consentType.name ?? ''}
          onConfirmDialog={handleRevokeConfirmation}
          onCancelDialog={handleCancelRevoke}
        />
      )}
    </>
  );
};
