import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useInteraction } from '@/hooks';
import { HomepageStep } from '@/pages/Homepage';
import { parseParticipants } from '@/services';
import { useGetRoomByCodeLazyQuery } from '@/services/graphql';
import { ActivityTypeBaseType } from '@/services/graphql/types/types';
import { addAttendeeInfoToSentry } from '@/utils';

type RoomCodeFormProps = {
  onChangeHomepageStep: Dispatch<SetStateAction<HomepageStep>>;
};

export const RoomCodeForm = ({ onChangeHomepageStep }: RoomCodeFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [enteredRoomCode, setEnteredRoomCode] = useState('');
  const [isRoomCodeValid, setIsRoomCodeValid] = useState(true);

  const {
    setRoomId,
    setRoomCode,
    setAttendee,
    setOrganizer,
    setCoOrganizers,
    setInteractionMode,
    setIsScreenSharingEnable,
  } = useInteraction();
  const [searchRoom, { loading }] = useGetRoomByCodeLazyQuery();

  const onChangeRoomCode = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredRoomCode(event.target.value);
    setIsRoomCodeValid(true);
  };

  const onSubmitRoomCode = async () => {
    const onlyContainsNumbers = /^\d+$/.test(enteredRoomCode);
    if (!onlyContainsNumbers) {
      setIsRoomCodeValid(false);

      return;
    }

    const { data } = await searchRoom({
      variables: {
        code: enteredRoomCode,
      },
    });
    if (data && data.anonRemoteInteractionByCode) {
      const activity = data.anonRemoteInteractionByCode;

      setRoomId?.(activity.id);
      setRoomCode?.(enteredRoomCode);

      const { apiOrganizer, apiAttendee, apiCoOrganizers } =
        parseParticipants(activity);

      if (apiAttendee) {
        setAttendee?.(apiAttendee);
        addAttendeeInfoToSentry(apiAttendee);
      }

      apiOrganizer && setOrganizer?.(apiOrganizer);
      setCoOrganizers?.(apiCoOrganizers);

      setIsScreenSharingEnable?.(activity.attendeeScreenSharingAllowed);
      setIsRoomCodeValid(true);

      if (activity.baseType === ActivityTypeBaseType.Telephone) {
        setInteractionMode('phone');
        navigate(`/room/${activity.id}`);
      } else if (activity.baseType === ActivityTypeBaseType.Remote) {
        setInteractionMode('remote');
        onChangeHomepageStep('permissions');
      }
    } else {
      setIsRoomCodeValid(false);
    }
  };

  return (
    <Wrapper>
      <Title variant="h5">{t('pages.homepage.welcome')}</Title>
      <Typography variant="body2">
        {t('pages.homepage.explanationText')}
      </Typography>

      <FormWrapper>
        <RoomCodeInput
          label={t('pages.homepage.accessCode')}
          error={!isRoomCodeValid && !!enteredRoomCode}
          helperText={t('pages.homepage.invalidAccessCode')}
          value={enteredRoomCode}
          type={'number'}
          inputProps={{
            maxLength: 6,
            minLength: 6,
          }}
          onChange={onChangeRoomCode}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Enter') {
              onSubmitRoomCode();
            }
          }}
        />

        <SubmitButton
          loading={loading}
          variant="contained"
          disabled={enteredRoomCode.length !== 6}
          onClick={onSubmitRoomCode}
        >
          {t('pages.homepage.join')}
        </SubmitButton>
      </FormWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  margin: `${theme.spacing(2.25)} 0`,
}));

const Title = styled(Typography)({
  textAlign: 'center',
});

const FormWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const RoomCodeInput = styled(TextField)({
  '.MuiFormHelperText-root': {
    visibility: 'hidden',
    '&.Mui-error': {
      visibility: 'visible',
    },
  },
});

const SubmitButton = styled(LoadingButton)({
  textTransform: 'uppercase',
});
