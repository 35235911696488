import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import {
  Backdrop as MuiBackdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePermissionPrompt } from '@/hooks';

export const PermissionsContent = () => {
  const { t } = useTranslation();
  const [isCameraChecked, setIsCameraChecked] = useState(true);
  const [isMicChecked, setIsMicChecked] = useState(true);

  const { isRunningPermissionsPrompt, requestPermissions } =
    usePermissionPrompt();

  const handleChangeCameraSwitch = () => {
    setIsCameraChecked((currentValue) => !currentValue);
  };

  const handleChangeMicSwitch = () => {
    setIsMicChecked((currentValue) => !currentValue);
  };

  return (
    <Container data-testid="permissions-content">
      <Typography variant="h5">
        {t('pages.homepage.permissions.getReady')}
      </Typography>

      <MediumStack>
        <Typography variant="body2">
          <Trans
            i18nKey="pages.homepage.permissions.pleaseSetMessage"
            components={{ bold: <strong /> }}
          />
        </Typography>

        <LineBox>
          <IconTextBox>
            <StyledVideocamIcon />
            <SecondaryTypography variant="body1">
              {t('pages.homepage.permissions.useYourCamera')}
            </SecondaryTypography>
          </IconTextBox>
          <Box>
            <Switch
              checked={isCameraChecked}
              onChange={handleChangeCameraSwitch}
            />
          </Box>
        </LineBox>

        <TinyStack>
          <LineBox>
            <IconTextBox>
              <StyledMicIcon />
              <SecondaryTypography variant="body1">
                {t('pages.homepage.permissions.useYourMic')}
              </SecondaryTypography>
            </IconTextBox>
            <Box>
              <Switch checked={isMicChecked} onChange={handleChangeMicSwitch} />
            </Box>
          </LineBox>

          <ErrorTypography
            variant="caption"
            isVisible={!isCameraChecked && !isMicChecked}
          >
            {t('pages.homepage.permissions.allowMessage')}
          </ErrorTypography>
        </TinyStack>

        <TinyStack>
          <Button
            disabled={!isCameraChecked && !isMicChecked}
            variant="contained"
            onClick={() => requestPermissions(isCameraChecked, isMicChecked)}
          >
            {t('components.common.save')}
          </Button>
          <SubTypography variant="body2">
            {t('pages.homepage.permissions.browserConfirmMessage')}
          </SubTypography>
        </TinyStack>
      </MediumStack>

      <Backdrop
        open={isRunningPermissionsPrompt}
        data-testid="backdrop-with-loader"
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  gap: theme.spacing(3),

  width: '100%',
}));

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  width: '100%',
}));

const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const LineBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SecondaryTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const SubTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textAlign: 'center',
}));

const ErrorTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  color: theme.palette.error.main,
  visibility: isVisible ? 'visible' : 'hidden',
}));

const StyledVideocamIcon = styled(VideocamIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledMicIcon = styled(MicIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const IconTextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  color: theme.palette.common.white,
}));
