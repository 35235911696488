import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CUSTOM_BREAKPOINTS } from '@ysura/common';
import { ReactNode } from 'react';

type CallContentWrapperProps = {
  children: ReactNode;
};

export const CallContentWrapper = ({ children }: CallContentWrapperProps) => {
  return <Wrapper data-testid="call-content-wrapper">{children}</Wrapper>;
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),

  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    background: theme.palette.grey[800],
    padding: theme.spacing(1),
    gap: theme.spacing(0),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
}));
