import * as Apollo from '@apollo/client';

import * as Operations from '../queries';
import type * as Types from '../types/types';

const defaultOptions = {} as const;
export type AddRemoteInteractionFeedbackMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  attendeeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  rating: Types.Scalars['Int']['input'];
  content?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AddRemoteInteractionFeedbackMutation = {
  __typename?: 'Mutation';
  addRemoteInteractionFeedback: boolean;
};

export type AddRemoteInteractionFeedbackMutationFn = Apollo.MutationFunction<
  AddRemoteInteractionFeedbackMutation,
  AddRemoteInteractionFeedbackMutationVariables
>;

/**
 * __useAddRemoteInteractionFeedbackMutation__
 *
 * To run a mutation, you first call `useAddRemoteInteractionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRemoteInteractionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRemoteInteractionFeedbackMutation, { data, loading, error }] = useAddRemoteInteractionFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      attendeeId: // value for 'attendeeId'
 *      rating: // value for 'rating'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddRemoteInteractionFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRemoteInteractionFeedbackMutation,
    AddRemoteInteractionFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddRemoteInteractionFeedbackMutation,
    AddRemoteInteractionFeedbackMutationVariables
  >(Operations.addRemoteInteractionFeedback, options);
}
export type AddRemoteInteractionFeedbackMutationHookResult = ReturnType<
  typeof useAddRemoteInteractionFeedbackMutation
>;
export type AddRemoteInteractionFeedbackMutationResult =
  Apollo.MutationResult<AddRemoteInteractionFeedbackMutation>;
export type AddRemoteInteractionFeedbackMutationOptions =
  Apollo.BaseMutationOptions<
    AddRemoteInteractionFeedbackMutation,
    AddRemoteInteractionFeedbackMutationVariables
  >;
