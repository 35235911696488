export { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@ysura/common';

export const HOMEPAGE_FOOTER = {
  HEIGHT: '44px',
};

export const HOMEPAGE_GRAPHIC = {
  DESKTOP_SIZE: '300px',
  MOBILE_SIZE: '200px',
};

export const HOMEPAGE_CARD_CONTENT = {
  WIDTH: '368px',
};

export const HOMEPAGE_DIALOG = {
  WIDTH: '847px',
  HEIGHT: '480px',
};
