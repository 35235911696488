import { Box, Button, Stack, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  DeliveryInformation,
  DeliveryTimeFrameData,
  PersonData,
  PromotionalMaterialGroupData,
  RequestedSamples,
  SampleRequestCollectState,
  SampleRequestPreview,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

export const SampleRequestCollection = () => {
  const {
    sharingSampleRequest,
    setIsFormSubmitting,
    broadcastSampleRequestValueChange,
    broadcastSampleRequestSubmit,
  } = useInteraction();

  const [title, setTitle] = useState<string>(
    sharingSampleRequest?.state?.title ?? ''
  );
  const [addressValue, setAddressValue] = useState<string>(
    sharingSampleRequest?.state?.address ?? ''
  );
  const [addressExtension, setAddressExtension] = useState(
    sharingSampleRequest?.state?.addressExtension ?? ''
  );
  const [isDeliveryDateEnabled, setIsDeliveryDateEnabled] = useState(false);
  const [isDeliveryInformationEnabled, setIsDeliveryInformationEnabled] =
    useState(false);
  const [signature, setSignature] = useState<string>(
    sharingSampleRequest?.state?.signature ?? ''
  );
  const [attendee, setAttendee] = useState<PersonData | undefined>();
  const [deliveryDateMinimumDays, setDeliveryDateMinimumDays] = useState(0);
  const [deliveryTimeFrame, setDeliveryTimeFrame] = useState<string | null>(
    null
  );
  const [deliveryTimeFrames, setDeliveryTimeFrames] = useState<
    DeliveryTimeFrameData[]
  >([]);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [isSampleRequestSigned, setIsSampleRequestSigned] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !addressValue || !signature
  );
  const [promotionalMaterialGroups, setPromotionalMaterialGroups] = useState<
    Array<PromotionalMaterialGroupData>
  >([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (sharingSampleRequest?.state?.step === 'collect') {
      const state = sharingSampleRequest.state;
      setTitle(sharingSampleRequest.state.title);
      setAddressValue(state.address);
      setAddressExtension(state.addressExtension);
      setIsDeliveryDateEnabled(state.isDeliveryDateEnabled);
      setIsDeliveryInformationEnabled(state.isDeliveryInformationEnabled);
      setAttendee(state.attendee);
      setDeliveryDateMinimumDays(state.deliveryDateMinimumDays);
      setDeliveryTimeFrame(state.deliveryTimeFrame);
      setDeliveryTimeFrames(state.deliveryTimeFrames);
      setDeliveryDate(state.deliveryDate);
      setIsSampleRequestSigned(state.isSampleRequestSigned);
      setPromotionalMaterialGroups(state.requestedSamples);
      setSignature(state.signature);
    }
  }, [sharingSampleRequest]);

  useEffect(() => {
    setIsButtonDisabled(
      !addressValue || !signature || sharingSampleRequest?.step === 'collect'
    );
  }, [addressValue, sharingSampleRequest, signature]);

  const handleDataChange = (
    currentAddressExtension: string,
    currentAddressValue: string,
    currentDeliveryDate: Date | null,
    currentDeliveryTimeFrame: string | null,
    signature: string
  ) => {
    const state: SampleRequestCollectState = {
      step: 'collect',
      title: title,
      attendee: attendee,
      requestedSamples: promotionalMaterialGroups,
      address: currentAddressValue,
      addressExtension: currentAddressExtension,
      isDeliveryInformationEnabled: isDeliveryInformationEnabled,
      isDeliveryDateEnabled: isDeliveryDateEnabled,
      deliveryTimeFrame: currentDeliveryTimeFrame,
      deliveryTimeFrames: deliveryTimeFrames,
      deliveryDate: currentDeliveryDate,
      deliveryDateMinimumDays: deliveryDateMinimumDays,
      isSampleRequestSigned: isSampleRequestSigned,
      signature: signature,
    };

    const payload: SampleRequestViewerPayload = {
      id: sharingSampleRequest?.id ?? '',
      step: 'collect',
      state: state,
    };
    broadcastSampleRequestValueChange?.(payload);
  };

  const handleOnChangeAddressExtension = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAddressExtension(event.target.value);
    handleDataChange(
      event.target.value,
      addressValue,
      deliveryDate,
      deliveryTimeFrame,
      signature
    );
  };

  const handleChangeAddressCheckbox = (value: string) => {
    setAddressValue(value);
    handleDataChange(
      addressExtension,
      value,
      deliveryDate,
      deliveryTimeFrame,
      signature
    );
  };

  const handleChangeDeliveryTimeFrame = (
    event: SelectChangeEvent<string | null>
  ) => {
    setDeliveryTimeFrame(event.target.value ?? '');
    handleDataChange(
      addressExtension,
      addressValue,
      deliveryDate,
      event.target.value ?? '',
      signature
    );
  };

  const handleChangeDeliveryDate = (value: Date | null) => {
    setDeliveryDate(value);
    handleDataChange(
      addressExtension,
      addressValue,
      value,
      deliveryTimeFrame,
      signature
    );
  };

  const handleSignatureChange = (value: string) => {
    setSignature(value);
    handleDataChange(
      addressExtension,
      addressValue,
      deliveryDate,
      deliveryTimeFrame,
      value
    );
  };

  const handleSubmit = () => {
    if (sharingSampleRequest?.step === 'preview') {
      setIsFormSubmitting?.(true);
      broadcastSampleRequestSubmit?.(sharingSampleRequest);
      setIsButtonDisabled(true);
    }
  };

  return (
    <Wrapper>
      <Header>
        <Typography variant="h5">{title}</Typography>
        <Container>
          {!isButtonDisabled && (
            <Button variant="contained" onClick={handleSubmit}>
              {t('components.common.submit')}
            </Button>
          )}
        </Container>
      </Header>
      <Content>
        {sharingSampleRequest?.state.step === 'collect' && (
          <StyledStack data-testid="sample-request-collection">
            <RequestedSamples
              title={`${t('pages.room.requestedSamples')}:`}
              promats={promotionalMaterialGroups.filter(
                (promat) => promat.promotionalMaterial?.signatureRequired
              )}
              isSigned={isSampleRequestSigned}
            />

            <DeliveryInformation
              addressExtension={addressExtension}
              initialAddress={addressValue}
              firstAttendee={attendee}
              deliveryTimeFrame={deliveryTimeFrame}
              deliveryDate={deliveryDate}
              deliveryTimeFrameEnabled={isDeliveryInformationEnabled}
              deliveryDateEnabled={isDeliveryDateEnabled}
              deliveryDateMinimumDays={deliveryDateMinimumDays}
              deliveryTimeFrames={deliveryTimeFrames}
              signature={signature}
              onChangeDeliveryTimeFrame={handleChangeDeliveryTimeFrame}
              onChangeDeliveryDate={handleChangeDeliveryDate}
              onChangeAddressExtension={handleOnChangeAddressExtension}
              onChangeAddress={handleChangeAddressCheckbox}
              onChangeSignature={handleSignatureChange}
            />
          </StyledStack>
        )}
        {sharingSampleRequest?.state?.step === 'preview' && (
          <SampleRequestPreview state={sharingSampleRequest.state} />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: theme.spacing(2),
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  overflow: 'auto',
});

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));
