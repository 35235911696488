import { Slide } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const NotistackProvider = ({ children }: Props) => {
  return (
    <SnackbarProvider
      dense
      preventDuplicate
      maxSnack={5}
      autoHideDuration={3000}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {children}
    </SnackbarProvider>
  );
};
