import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type FeedbackStaticBlockProps = {
  children: ReactNode;
};

export const FeedbackStaticBlock = ({ children }: FeedbackStaticBlockProps) => {
  return <Card>{children}</Card>;
};

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  padding: theme.spacing(10),
  gap: theme.spacing(3),

  background: theme.palette.background.neutral,

  [theme.breakpoints.down('md')]: {
    width: '100%',

    gap: theme.spacing(3),
    padding: theme.spacing(3),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
      height: '100%',
      width: '100%',
    },
}));
