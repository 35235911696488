export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Built-in String */
  Date: { input: string; output: string };
  /** Built-in String */
  DateTime: { input: string; output: string };
};

export enum ActivityTypeBaseType {
  /** When a remote interaction is open from ysura CRM */
  Remote = 'REMOTE',
  /** When an telephone channel is used for the activity */
  Telephone = 'TELEPHONE',
}

export type AddRemoteInteractionFeedbackInput = {
  /** Hash Id of the remote interaction attendee. */
  attendeeId?: InputMaybe<Scalars['ID']['input']>;
  /** Content of the feedback. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Hash Id of the remote interaction. */
  id: Scalars['ID']['input'];
  /** Rating if the feedback. Number between 1-5. */
  rating: Scalars['Int']['input'];
};

export type AnonRemoteInteraction = {
  __typename?: 'AnonRemoteInteraction';
  /** Shows if attendee/organizer is allowed to share the screen */
  attendeeScreenSharingAllowed: Scalars['Boolean']['output'];
  /** Base Type of the activity type: TELEPHONE or REMOTE */
  baseType: ActivityTypeBaseType;
  /** End date of the activity */
  endDate?: Maybe<Scalars['Date']['output']>;
  /** End date and time of the activity */
  endDateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Hash Id. It uniquely identifies the entity across the system. */
  id: Scalars['ID']['output'];
  /** List of all the participants of the activity */
  participants: Array<AnonRemoteInteractionParticipant>;
  /** Start date of the activity */
  startDate?: Maybe<Scalars['Date']['output']>;
  /** Start date and time of the activity */
  startDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type AnonRemoteInteractionParticipant = {
  __typename?: 'AnonRemoteInteractionParticipant';
  /** Hash Id of the person of the participant. */
  id: Scalars['ID']['output'];
  /** The name of the participant, this is optional */
  name?: Maybe<Scalars['String']['output']>;
  /** The role of the participant, e.g. ORGANIZER, ATTENDEE, CO_ORGANIZER */
  role: AnonRemoteInteractionParticipantRole;
};

export enum AnonRemoteInteractionParticipantRole {
  Attendee = 'ATTENDEE',
  CoOrganizer = 'CO_ORGANIZER',
  Organizer = 'ORGANIZER',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a remote interaction feedback */
  addRemoteInteractionFeedback: Scalars['Boolean']['output'];
};

export type MutationAddRemoteInteractionFeedbackArgs = {
  input: AddRemoteInteractionFeedbackInput;
};

export type Query = {
  __typename?: 'Query';
  /** Finds a remote interaction by the activity ID and optional attendee ID */
  anonRemoteInteraction?: Maybe<AnonRemoteInteraction>;
  /** Finds a remote interaction by 6 digit code */
  anonRemoteInteractionByCode?: Maybe<AnonRemoteInteraction>;
};

export type QueryAnonRemoteInteractionArgs = {
  attendeeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type QueryAnonRemoteInteractionByCodeArgs = {
  code: Scalars['String']['input'];
};
