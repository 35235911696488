import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageOverlay, varFade } from '@ysura/common';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const RemoteInteractionLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <Main
          key="room-page-layout"
          {...varFade({ durationIn: 0.4, easeIn: 'easeIn' }).in}
        >
          <Outlet />
        </Main>
      </Wrapper>

      <PageOverlay
        animate="enter"
        overlayText={t('components.transition.joiningWaitingRoom')}
      />
    </>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',

  height: '100%',

  background: theme.palette.background.interaction,
}));

const Main = styled(motion.main)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,

  width: '100%',
  height: '100%',
  maxWidth: theme.breakpoints.values.lg,

  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,

  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
    padding: 0,
  },
}));
