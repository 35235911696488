import { useCallback, useState } from 'react';

import { useInteraction } from '@/hooks';

export const usePermissionPrompt = () => {
  const [isRunningPermissionsPrompt, setIsRunningPermissionsPrompt] =
    useState(false);

  const {
    setCameraPermissionState,
    setMicrophonePermissionState,
    setArePermissionsChecked,
  } = useInteraction();

  const requestPermissions = useCallback(
    (isCameraChecked: boolean, isMicChecked: boolean) => {
      setIsRunningPermissionsPrompt(true);

      if (isCameraChecked && isMicChecked) {
        navigator.mediaDevices
          ?.getUserMedia({ audio: true, video: true })
          .then(() => {
            setCameraPermissionState?.('granted');
            setMicrophonePermissionState?.('granted');
          })
          .catch(() => {
            setCameraPermissionState?.('denied');
            setMicrophonePermissionState?.('denied');
          })
          .finally(() => {
            setArePermissionsChecked?.(true);
            setIsRunningPermissionsPrompt(false);
          });
      } else if (isCameraChecked) {
        navigator.mediaDevices
          ?.getUserMedia({ video: true })
          .then(() => {
            setCameraPermissionState?.('granted');
          })
          .catch(() => {
            setCameraPermissionState?.('denied');
          })
          .finally(() => {
            setArePermissionsChecked?.(true);
            setIsRunningPermissionsPrompt(false);
          });
      } else if (isMicChecked) {
        navigator.mediaDevices
          ?.getUserMedia({ audio: true })
          .then(() => {
            setMicrophonePermissionState?.('granted');
          })
          .catch(() => {
            setMicrophonePermissionState?.('denied');
          })
          .finally(() => {
            setArePermissionsChecked?.(true);
            setIsRunningPermissionsPrompt(false);
          });
      }
    },
    [
      setArePermissionsChecked,
      setCameraPermissionState,
      setMicrophonePermissionState,
    ]
  );

  return { isRunningPermissionsPrompt, requestPermissions };
};
