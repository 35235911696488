import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConsentCollectionColumn, MediaSharingColumn } from '@ysura/common';
import { useEffect, useState } from 'react';

import { ConsentCollection, PDFMedia } from '@/components/RemoteInteraction';
import { useInteraction } from '@/hooks';
import {
  SampleRequestCollectionColumn,
  ScreenShareColumn,
} from '@/views/Interaction/Common';

import { InteractionVideoAndControls } from './InteractionVideoAndControls';

type InteractionContentProps = {
  openLeaveInteractionDialog: VoidFunction;
};

export const InteractionContent = ({
  openLeaveInteractionDialog,
}: InteractionContentProps) => {
  const {
    sharingConsent,
    isMediaShared,
    isAnyContentShared,
    sharingSampleRequest,
    sharingMedia,
    broadcastPointerShow,
    onMediaStateChanged,
    onPointerShown,
  } = useInteraction();

  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (sharingConsent) {
      setFormKey((currentValue) => currentValue + 1);
    }
  }, [sharingConsent]);

  return (
    <InteractionContentWrapper
      isSharing={isAnyContentShared}
      data-testid="interaction-content-wrapper"
    >
      <InteractionVideoAndControls
        openLeaveInteractionDialog={openLeaveInteractionDialog}
        showMoreButton={isAnyContentShared}
      />

      {/* With the formKey we make sure the rendered iframe is refreshed every time a new form is shared */}
      {!!sharingConsent && (
        <ConsentCollectionColumn key={formKey}>
          <ConsentCollection />
        </ConsentCollectionColumn>
      )}

      {sharingSampleRequest && <SampleRequestCollectionColumn />}

      {isMediaShared && sharingMedia?.media && (
        <MediaSharingColumn
          media={sharingMedia?.media}
          initialPage={sharingMedia?.initialPage}
          broadcastPointerShow={broadcastPointerShow}
          PdfMediaComponent={
            <PDFMedia
              media={sharingMedia?.media}
              initialPage={sharingMedia?.initialPage?.indexh}
            />
          }
          onMediaStateChanged={onMediaStateChanged}
          onPointerShown={onPointerShown}
        />
      )}

      <ScreenShareColumn />
    </InteractionContentWrapper>
  );
};

const InteractionContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSharing',
})<{ isSharing: boolean }>(({ theme, isSharing }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  gap: theme.spacing(3),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    background: theme.palette.grey[800],

    flexDirection: isSharing ? 'column' : 'row',
    gap: 0,
  },
}));
