import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInteraction } from '@/hooks';

import { PermissionsContent } from './PermissionsContent';

export const Permissions = () => {
  const navigate = useNavigate();
  const {
    arePermissionsChecked,
    cameraPermissionState,
    microphonePermissionState,
    roomId,
  } = useInteraction();

  /**
   * When the permissions were granted & they are not all pending,
   * navigate to the room
   */

  useEffect(() => {
    if (
      arePermissionsChecked &&
      !(
        cameraPermissionState === 'pending' &&
        microphonePermissionState === 'pending'
      ) &&
      roomId
    ) {
      navigate(`/room/${roomId}`);
    }
  }, [
    navigate,
    roomId,
    arePermissionsChecked,
    cameraPermissionState,
    microphonePermissionState,
  ]);

  return <PermissionsContent />;
};
