import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { CallGraphic } from '@/views/Interaction/Telephone/Interaction';

type CallContentInfoProps = {
  isMobile?: boolean;
};

export const CallContentInfo = ({ isMobile }: CallContentInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container data-testid="call-content-info">
      <StaticBlock>
        <CallGraphicStyled data-testid="call-content-info-image">
          <CallGraphic />
        </CallGraphicStyled>
        <InfoWrapper data-testid="call-content-info-messages">
          <Typography
            variant={isMobile ? 'subtitle2' : 'h3'}
            color={theme.palette.text.disabled}
          >
            {t('pages.telephone.call.inCallWith')}
          </Typography>

          <TypographyStyled variant="body2" color={theme.palette.common.white}>
            {t('pages.telephone.call.waitingForOrganizer')}
          </TypographyStyled>
        </InfoWrapper>
      </StaticBlock>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',

  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',

  overflow: 'hidden',

  background: theme.palette.grey[700],
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));

const StaticBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      alignItems: 'center',
    },
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 0,
    textAlign: 'center',
  },
}));

const CallGraphicStyled = styled(Box)(({ theme }) => ({
  aspectRatio: '.5',
  alignContent: 'center',
  padding: `0 ${theme.spacing(1)}`,

  [theme.breakpoints.down('md')]: {
    aspectRatio: '1',
    padding: `0 ${theme.spacing(4)}`,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: '50%',
      padding: 0,
      justifyContent: 'center',
    },
}));

const TypographyStyled = styled(Typography)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
}));
