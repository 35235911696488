import { Button } from '@mui/material';
import { ErrorPage } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SomethingWentWrongImage from '@/assets/somethingWentWrong.svg';

export const NotFound404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleDashboardNavigation = () => navigate('/');

  return (
    <ErrorPage
      title={t('pages.error.pageNotFound')}
      subtitleLine1={t('pages.error.pageNotFoundDescription')}
      imageFile={SomethingWentWrongImage}
      imageAlt="404 page not found"
      actionButton={
        <Button variant="contained" onClick={handleDashboardNavigation}>
          {t('pages.feedback.backToAccessPage')}
        </Button>
      }
    />
  );
};
