import { Mic, Videocam, VolumeUp } from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, TextField, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper } from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

type AudioVideoSourceSelectProps = {
  isInDialog?: boolean;
};

export const AudioVideoSourceSelect = ({
  isInDialog = false,
}: AudioVideoSourceSelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    cameraPermissionState,
    microphonePermissionState,
    audioInputDevices,
    // audioOutputDevices,
    videoDevices,
    setSelectedAudioInput,
    setSelectedAudioOutput,
    setSelectedVideoInput,
    selectedVideoInput,
    selectedAudioInput,
    // selectedAudioOutput,
  } = useInteraction();

  const handleChangeDevice = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    const { value } = event.target;

    switch (type) {
      case 'video':
        setSelectedVideoInput?.(value);
        break;
      case 'audioinput':
        setSelectedAudioInput?.(value);
        break;
      case 'audiooutput':
        setSelectedAudioOutput?.(value);
        break;
    }
  };

  const renderLabel = (type: 'camera' | 'mic' | 'speaker') => {
    return (
      <LabelContainer>
        {type === 'camera' && (
          <>
            <Videocam /> {`${t('pages.room.audioVideoSettings.camera')}`}
          </>
        )}
        {type === 'mic' && (
          <>
            <Mic /> {`${t('pages.room.audioVideoSettings.mic')}`}
          </>
        )}
        {type === 'speaker' && (
          <>
            <VolumeUp /> {`${t('pages.room.audioVideoSettings.speaker')}`}
          </>
        )}
      </LabelContainer>
    );
  };

  const renderNotAllowedLabel = (type: 'camera' | 'mic') => {
    return (
      <LabelContainer>
        <IconWrapper size={20} backgroundColor={theme.palette.error.main}>
          <ExclamationMarkIcon />
        </IconWrapper>

        <LabelText>
          {type === 'camera' &&
            `${t('pages.room.audioVideoSettings.cameraNotAllowed')}`}
          {type === 'mic' &&
            `${t('pages.room.audioVideoSettings.micNotAllowed')}`}
        </LabelText>
      </LabelContainer>
    );
  };

  return (
    <Container isInDialog={isInDialog} data-testid="audio-video-source-select">
      <TextField
        fullWidth
        select={
          microphonePermissionState === 'granted' &&
          audioInputDevices?.length > 0
        }
        data-testid="select-mic"
        disabled={
          microphonePermissionState !== 'granted' ||
          audioInputDevices?.length === 0
        }
        value={
          microphonePermissionState === 'granted' ? selectedAudioInput : ''
        }
        label={
          microphonePermissionState !== 'granted'
            ? renderNotAllowedLabel('mic')
            : renderLabel('mic')
        }
        SelectProps={{
          native: true,
        }}
        onChange={(e) => handleChangeDevice(e, 'audioinput')}
      >
        {audioInputDevices.map((device) => {
          return (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          );
        })}
      </TextField>

      <TextField
        fullWidth
        select={cameraPermissionState === 'granted' && videoDevices?.length > 0}
        data-testid="select-camera"
        disabled={
          cameraPermissionState !== 'granted' || videoDevices?.length === 0
        }
        value={cameraPermissionState === 'granted' ? selectedVideoInput : ''}
        label={
          cameraPermissionState !== 'granted'
            ? renderNotAllowedLabel('camera')
            : renderLabel('camera')
        }
        SelectProps={{
          native: true,
        }}
        onChange={(e) => handleChangeDevice(e, 'video')}
      >
        {videoDevices.map((device) => {
          return (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          );
        })}
      </TextField>

      {/*<TextField*/}
      {/*  fullWidth*/}
      {/*  select={*/}
      {/*    microphonePermissionState === 'granted' &&*/}
      {/*    audioOutputDevices?.length > 0*/}
      {/*  }*/}
      {/*  data-testid="select-speaker"*/}
      {/*  disabled={*/}
      {/*    microphonePermissionState !== 'granted' ||*/}
      {/*    audioOutputDevices?.length === 0*/}
      {/*  }*/}
      {/*  value={*/}
      {/*    microphonePermissionState === 'granted' &&*/}
      {/*    audioOutputDevices?.length > 0*/}
      {/*      ? selectedAudioOutput*/}
      {/*      : t('pages.room.audioVideoSettings.default')*/}
      {/*  }*/}
      {/*  label={renderLabel('speaker')}*/}
      {/*  SelectProps={{*/}
      {/*    native: true,*/}
      {/*  }}*/}
      {/*  onChange={(e) => handleChangeDevice(e, 'audioouput')}*/}
      {/*>*/}
      {/*  {audioOutputDevices.map((device) => {*/}
      {/*    return (*/}
      {/*      <option key={device.deviceId} value={device.deviceId}>*/}
      {/*        {device.label}*/}
      {/*      </option>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</TextField>*/}
    </Container>
  );
};

type ContainerStyleProps = {
  isInDialog: boolean;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInDialog',
})<ContainerStyleProps>(({ theme, isInDialog }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  flexDirection: isInDialog ? 'column' : 'row',
  paddingTop: isInDialog ? theme.spacing(1) : 0,
}));

const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(0.5),
}));

const LabelText = styled(Box)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const ExclamationMarkIcon = styled(PriorityHighIcon)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[0],
}));
