import { Loader } from '@ysura/common';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';

import { useInteraction } from '@/hooks';
import {
  Feedback,
  InteractionAnimate,
  WaitingRoomAnimate,
} from '@/views/Interaction/Common';
import {
  TelephoneCall,
  TelephoneWaitingRoom,
} from '@/views/Interaction/Telephone';

export const TelephoneInteraction = () => {
  const {
    roomId,
    initializeStateServer,
    leaveInteraction,
    broadcastWaitingRoomToInteraction,
    getInteractionStep,
    createOrUpdateInteractionStep,
    isStateServerInitialized,
  } = useInteraction();

  const currentStep = getInteractionStep(roomId);

  useEffect(() => {
    // Initialize the room, if it hasn't been already
    if (
      roomId &&
      (currentStep === 'waiting-room' || currentStep === 'interaction')
    ) {
      initializeStateServer({
        roomId,
        interactionStep: currentStep,
      });
    }
  }, [roomId, currentStep, initializeStateServer]);

  useEffect(() => {
    /**
     * If interaction step data for a roomId does not exist,
     * create a new one with default step value (waiting-room).
     */
    if (!currentStep && roomId) {
      createOrUpdateInteractionStep(roomId, 'waiting-room');
    }
  }, [currentStep, roomId, createOrUpdateInteractionStep]);

  const handleStartInteraction = () => {
    createOrUpdateInteractionStep(roomId, 'interaction');

    broadcastWaitingRoomToInteraction?.();
  };

  const handleLeaveInteraction = () => {
    createOrUpdateInteractionStep(roomId, 'feedback');
    leaveInteraction();
  };

  if (currentStep === 'feedback') {
    return <Feedback roomId={roomId} />;
  }

  return isStateServerInitialized ? (
    <AnimatePresence exitBeforeEnter>
      {currentStep === 'waiting-room' && (
        <WaitingRoomAnimate key="telephone-waiting-room-animate">
          <TelephoneWaitingRoom
            handleStartInteraction={handleStartInteraction}
          />
        </WaitingRoomAnimate>
      )}
      {currentStep === 'interaction' && (
        <InteractionAnimate key="telephone-interaction-room-animate">
          <TelephoneCall handleLeaveInteraction={handleLeaveInteraction} />
        </InteractionAnimate>
      )}
    </AnimatePresence>
  ) : (
    <Loader />
  );
};
