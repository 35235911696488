import CallEndIcon from '@mui/icons-material/CallEnd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RoundButton } from '@ysura/common';
import { MouseEvent, useState } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { ScreenShareButton } from '@/views/Interaction/Common';

import {
  ToggleCameraButton,
  ToggleMicButton,
  ToggleSettingsButton,
} from '../Buttons';
import { SeeMore } from './SeeMore';

type VideoControlsProps = {
  openLeaveInteractionDialog: VoidFunction;
  showMoreButton: boolean;
};

export const VideoControls = ({
  openLeaveInteractionDialog,
  showMoreButton,
}: VideoControlsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { sharingMedia, isParticipantsScreenShared } = useInteraction();

  const buttonSize = isSmallScreen ? 'medium' : 'large';
  const handleOpenPopover = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    setIsPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  return (
    <>
      <Container
        data-testid="video-controls"
        isFullScreen={!!sharingMedia || isParticipantsScreenShared}
      >
        <ToggleMicButton size={buttonSize} />
        <ToggleCameraButton size={buttonSize} />

        {!showMoreButton && <ScreenShareButton variant="round" />}

        {!showMoreButton && <ToggleSettingsButton />}

        <RoundButton
          testId="end-call-button"
          size={buttonSize}
          variant="danger"
          onClick={openLeaveInteractionDialog}
        >
          <CallEndIcon />
        </RoundButton>
        {showMoreButton && (
          <RoundButton
            size="large"
            variant="secondary"
            onClick={(e: MouseEvent<HTMLElement>) => handleOpenPopover(e)}
          >
            <MoreVertIcon />
          </RoundButton>
        )}
      </Container>

      <SeeMore
        isPopoverOpen={isPopoverOpen}
        anchorEl={anchorEl}
        closePopover={handleClosePopover}
      />
    </>
  );
};

type ContainerStyleProps = {
  isFullScreen: boolean;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})<ContainerStyleProps>(({ theme, isFullScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: isFullScreen ? 'column' : 'row',

  gap: theme.spacing(3),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));
