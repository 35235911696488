import { Image } from '@ysura/common';

import FeedbackRating from '@/assets/feedback/graphic-rating.svg';
import FeedbackThankyou from '@/assets/feedback/graphic-thankyou.svg';

import { FeedbackStep } from './Feedback';

export const FeedbackGraphic = ({
  feedbackStep,
}: {
  feedbackStep: FeedbackStep;
}) => {
  return (
    <Image
      visibleByDefault
      src={feedbackStep === 'rating' ? FeedbackRating : FeedbackThankyou}
      data-testid="feedback-graphic"
      objectFit="contain"
    />
  );
};
