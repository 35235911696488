import {
  ConsentViewerPayload,
  Media,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction, useNotification } from '@/hooks';
import { ConsentActionManager } from '@/views/Interaction/Common';
import { TelephoneCallContent } from '@/views/Interaction/Telephone/Interaction/TelephoneCallContent';

type TelephoneCallProps = {
  handleLeaveInteraction: VoidFunction;
};
export const TelephoneCall = ({
  handleLeaveInteraction,
}: TelephoneCallProps) => {
  const { toast } = useNotification();
  const { t } = useTranslation();

  const {
    isFormSubmitting,
    isAnyContentShared,
    setIsFormSubmitting,
    setSharingConsent,
    setSharingMedia,
    setSharingSampleRequest,
    onSampleRequestOpened,
    onSampleRequestClosed,
    onSampleRequestValueChanged,
    //Consent form collection
    onConsentFormOpened,
    onConsentFormValueChanged,
    onConsentClosed,
    onMediaOpened,
    onMediaClosed,
    onOrganizerTerminateSession,
  } = useInteraction();

  const handleSessionTerminated = useCallback(() => {
    toast?.({ message: t('notifications.sessionEnded'), type: 'info' });

    handleLeaveInteraction();
  }, [handleLeaveInteraction, t, toast]);

  useEffect(() => {
    const handleShowConsentForm = (data: ConsentViewerPayload) => {
      setSharingConsent(data);
    };

    const handleHideConsentForm = () => {
      if (!isAnyContentShared) {
        return;
      }

      if (isFormSubmitting) {
        toast?.({
          message: t('notifications.consentFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('notifications.consentFormCancelled'),
          type: 'warning',
        });
      }

      setIsFormSubmitting(false);
      setSharingConsent(null);
    };

    const unsubscribeOnConsentFormOpen = onConsentFormOpened?.(
      handleShowConsentForm
    );
    const unsubscribeOnConsentClose = onConsentClosed?.(handleHideConsentForm);
    const unsubscribeOnConsentFormChange = onConsentFormValueChanged?.(
      handleShowConsentForm
    );

    return () => {
      if (unsubscribeOnConsentFormOpen) {
        unsubscribeOnConsentFormOpen();
      }
      if (unsubscribeOnConsentClose) {
        unsubscribeOnConsentClose();
      }
      if (unsubscribeOnConsentFormChange) {
        unsubscribeOnConsentFormChange();
      }
    };
  }, [
    onConsentFormOpened,
    onConsentClosed,
    setSharingConsent,
    onConsentFormValueChanged,
    isFormSubmitting,
    setIsFormSubmitting,
    toast,
    t,
    isAnyContentShared,
  ]);

  useEffect(() => {
    const handleShowSampleRequest = (data: SampleRequestViewerPayload) => {
      setSharingSampleRequest(data);
    };

    const handleHideSampleRequest = () => {
      if (isFormSubmitting) {
        toast?.({
          message: t('notifications.sampleRequestFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('notifications.sampleRequestFormCancelled'),
          type: 'warning',
        });
      }

      setIsFormSubmitting(false);
      setSharingSampleRequest(null);
    };

    const unsubscribeOnSampleRequestOpen = onSampleRequestOpened?.(
      handleShowSampleRequest
    );
    const unsubscribeOnSampleRequestClose = onSampleRequestClosed?.(
      handleHideSampleRequest
    );
    const unsubscribeOnSampleRequestChange = onSampleRequestValueChanged?.(
      handleShowSampleRequest
    );

    return () => {
      if (unsubscribeOnSampleRequestOpen) {
        unsubscribeOnSampleRequestOpen();
      }
      if (unsubscribeOnSampleRequestClose) {
        unsubscribeOnSampleRequestClose();
      }
      if (unsubscribeOnSampleRequestChange) {
        unsubscribeOnSampleRequestChange();
      }
    };
  }, [
    onSampleRequestOpened,
    onSampleRequestClosed,
    setSharingSampleRequest,
    onSampleRequestValueChanged,
    isFormSubmitting,
    setIsFormSubmitting,
    toast,
    t,
  ]);

  useEffect(() => {
    const handleShowMedia = (data: Media) => {
      setSharingMedia({ media: data });
    };

    const handleHideMedia = () => {
      setSharingMedia(null);
    };

    const unsubscribeOnMediaOpen = onMediaOpened?.(handleShowMedia);
    const unsubscribeOnMediaClose = onMediaClosed?.(handleHideMedia);

    return () => {
      if (unsubscribeOnMediaOpen) {
        unsubscribeOnMediaOpen();
      }
      if (unsubscribeOnMediaClose) {
        unsubscribeOnMediaClose();
      }
    };
  }, [onMediaClosed, onMediaOpened, setSharingMedia]);

  useEffect(() => {
    // handles the session terminating. Reasons for this can be:
    // - Organizer closes the room
    // - Organizer forcefully removes the attendee from the room
    // - The session expires
    const unsubscribeOnOrganizerTerminateSession =
      onOrganizerTerminateSession?.(handleSessionTerminated);

    return () => {
      unsubscribeOnOrganizerTerminateSession?.();
    };
  }, [handleSessionTerminated, onOrganizerTerminateSession]);

  return (
    <>
      <ConsentActionManager />
      <TelephoneCallContent handleLeaveInteraction={handleLeaveInteraction} />
    </>
  );
};
