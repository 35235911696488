import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageOverlay, varFade } from '@ysura/common';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { CUSTOM_BREAKPOINTS, HOMEPAGE_FOOTER } from '@/config/layout';

export const HomepageLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <Main
          key="homepage-layout"
          {...varFade({ delay: 0.4, durationOut: 0.4, easeOut: 'easeOut' }).in}
        >
          <Outlet />
        </Main>
      </Wrapper>

      <PageOverlay
        animate="exit"
        overlayText={t('components.transition.joiningWaitingRoom')}
      />
    </>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',

  // relative position needed for the correct placement of the footer
  position: 'relative',
  height: '100%',

  background: theme.palette.background.interaction,

  // adding footer height to padding value to avoid text overlapping due to absolute positioning
  padding: `calc(${theme.spacing(6)} + ${
    HOMEPAGE_FOOTER.HEIGHT
  }) ${theme.spacing(3)}`,

  [theme.breakpoints.down('md')]: {
    padding: 0,
    paddingBottom: `calc(${theme.spacing(4)} + ${HOMEPAGE_FOOTER.HEIGHT})`,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: 'auto',
      minHeight: '100%',
      padding: theme.spacing(2),
      paddingBottom: `calc(${theme.spacing(4)} + ${HOMEPAGE_FOOTER.HEIGHT})`,
    },
}));

const Main = styled(motion.main)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  alignSelf: 'center',

  width: '100%',
  height: '100%',
  maxWidth: theme.breakpoints.values.lg,

  [theme.breakpoints.down('md')]: {
    alignSelf: 'flex-start',

    maxWidth: 'unset',
  },
}));
