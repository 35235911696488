import * as Apollo from '@apollo/client';

import * as Operations from '../queries';
import type * as Types from '../types/types';

const defaultOptions = {} as const;
export type GetRoomByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  attendeeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetRoomByIdQuery = {
  __typename?: 'Query';
  anonRemoteInteraction?: {
    __typename?: 'AnonRemoteInteraction';
    id: string;
    startDate?: string | null;
    endDate?: string | null;
    startDateTime?: string | null;
    endDateTime?: string | null;
    attendeeScreenSharingAllowed: boolean;
    baseType: Types.ActivityTypeBaseType;
    participants: Array<{
      __typename?: 'AnonRemoteInteractionParticipant';
      id: string;
      name?: string | null;
      role: Types.AnonRemoteInteractionParticipantRole;
    }>;
  } | null;
};

/**
 * __useGetRoomByIdQuery__
 *
 * To run a query within a React component, call `useGetRoomByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useGetRoomByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomByIdQuery,
    GetRoomByIdQueryVariables
  > &
    (
      | { variables: GetRoomByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetRoomByIdQuery, GetRoomByIdQueryVariables>(
    Operations.getRoomById,
    options
  );
}
export function useGetRoomByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomByIdQuery,
    GetRoomByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetRoomByIdQuery, GetRoomByIdQueryVariables>(
    Operations.getRoomById,
    options
  );
}
export function useGetRoomByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRoomByIdQuery,
    GetRoomByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<GetRoomByIdQuery, GetRoomByIdQueryVariables>(
    Operations.getRoomById,
    options
  );
}
export type GetRoomByIdQueryHookResult = ReturnType<typeof useGetRoomByIdQuery>;
export type GetRoomByIdLazyQueryHookResult = ReturnType<
  typeof useGetRoomByIdLazyQuery
>;
export type GetRoomByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetRoomByIdSuspenseQuery
>;
export type GetRoomByIdQueryResult = Apollo.QueryResult<
  GetRoomByIdQuery,
  GetRoomByIdQueryVariables
>;
