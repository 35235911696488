import * as Sentry from '@sentry/react';

import { SENTRY } from '@/config';
import { InteractionParticipant } from '@/hooks/useInteraction';

export const addAttendeeInfoToSentry = (attendee: InteractionParticipant) => {
  if (SENTRY.ENABLED && SENTRY.USER_ENRICHMENT) {
    Sentry.setUser({
      id: attendee.personOid,
      username: attendee.displayName,
    });
  }
};
