import { useCallback, useState } from 'react';

import { useInteraction } from '@/hooks';

export const usePermissionCheck = () => {
  const {
    setCameraPermissionState,
    setMicrophonePermissionState,
    setArePermissionsChecked,
  } = useInteraction();

  const [isRunningPermissionsCheck, setIsRunningPermissionsCheck] =
    useState(true);

  // casting is necessary as some browsers do not allow querying mic and camera
  const micPermission = 'microphone' as PermissionName;
  const cameraPermission = 'camera' as PermissionName;

  const checkPermissionsWithPermissionAPI = useCallback(() => {
    const micPermissionQueryPromise = navigator.permissions
      ?.query({ name: micPermission })
      .then(({ state }) => {
        console.log('mic permissions in state: ', state);
        /**
         * To understand different permissions states,
         * check PermissionState in useInteraction.tsx
         */
        if (state === 'prompt') {
          setMicrophonePermissionState?.('pending');
        } else {
          setMicrophonePermissionState?.(state);
        }
      })
      .catch((error) => {
        console.log('error in mic permissions: ', error);

        throw error;
      });

    const cameraPermissionQueryPromise = navigator.permissions
      ?.query({ name: cameraPermission })
      .then(({ state }) => {
        console.log('camera permissions in state: ', state);

        if (state === 'prompt') {
          setCameraPermissionState?.('pending');
        } else {
          setCameraPermissionState?.(state);
        }
      })
      .catch((error) => {
        console.log('error in camera permissions: ', error);

        throw error;
      });

    Promise.all([
      micPermissionQueryPromise,
      cameraPermissionQueryPromise,
    ]).finally(() => {
      setArePermissionsChecked?.(true);
      setIsRunningPermissionsCheck(false);
    });
  }, [
    setArePermissionsChecked,
    setCameraPermissionState,
    setMicrophonePermissionState,
  ]);

  const checkPermissionsWithoutPermissionAPI = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then((devices = []) => {
      const isVideoLabelFound = Boolean(
        devices.find((device) => device?.kind === 'videoinput' && device?.label)
      );
      const isAudioLabelFound = Boolean(
        devices.find((device) => device?.kind === 'audioinput' && device?.label)
      );

      /**
       * If we've found labels for either video or audio,
       * we will run getUserMedia to get the actual permission status
       */
      if (isVideoLabelFound || isAudioLabelFound) {
        const getAudioMediaPromise = navigator.mediaDevices
          ?.getUserMedia({ audio: true })
          .then(() => {
            console.log('audio permission granted');
            setMicrophonePermissionState?.('granted');
          })
          .catch(() => {
            console.log('audio permission denied');
            setMicrophonePermissionState?.('denied');
          });

        const getVideoMediaPromise = navigator.mediaDevices
          ?.getUserMedia({ video: true })
          .then(() => {
            console.log('video permission granted');
            setCameraPermissionState?.('granted');
          })
          .catch(() => {
            console.log('video permission denied');
            setCameraPermissionState?.('denied');
          });

        Promise.all([getAudioMediaPromise, getVideoMediaPromise]).then(() => {
          setArePermissionsChecked?.(true);
          setIsRunningPermissionsCheck(false);
        });
      } else {
        /**
         * If no labels were found, we cannot be certain if the correct
         * permission is "pending" or "denied".
         * We will manage this case as a "pending" case.
         */
        setIsRunningPermissionsCheck(false);

        console.log(
          'no labels found for audio or video, cannot determine permissions status'
        );
      }
    });
  }, [
    setArePermissionsChecked,
    setCameraPermissionState,
    setMicrophonePermissionState,
  ]);

  const checkPermissions = useCallback(() => {
    try {
      navigator.permissions
        .query({ name: micPermission })
        .then(() => {
          checkPermissionsWithPermissionAPI();
        })
        .catch(() => {
          checkPermissionsWithoutPermissionAPI();
        });
    } catch {
      checkPermissionsWithoutPermissionAPI();
    }
  }, [checkPermissionsWithPermissionAPI, checkPermissionsWithoutPermissionAPI]);

  return { isRunningPermissionsCheck, checkPermissions };
};
