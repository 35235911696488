import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import {
  AddRemoteInteractionFeedbackMutation,
  AddRemoteInteractionFeedbackMutationVariables,
  useAddRemoteInteractionFeedbackMutation as useAddRemoteInteractionFeedbackMutationApollo,
} from '@/services/graphql';

type AddRemoteInteractionFeedbackVariables =
  // id, rating are required
  Pick<AddRemoteInteractionFeedbackMutationVariables, 'id' | 'rating'> &
    // content are optional
    Partial<
      Pick<
        AddRemoteInteractionFeedbackMutationVariables,
        'content' | 'attendeeId'
      >
    >;

/**
 * __useAddRemoteInteractionFeedbackMutation__
 *
 * To run a mutation, you first call `useAddRemoteInteractionFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRemoteInteractionFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRemoteInteractionFeedbackMutation, { data, loading, error }] = useAddRemoteInteractionFeedbackMutation({
 *   variables: {
 *      id: // value for activity 'id'
 *      rating: // int rating of the feedback
 *
 *      // Optional
 *      attendeeId: // value for activity attendee 'id'
 *      content: // string content of the feedback
 *   },
 * });
 */
export function useAddRemoteInteractionFeedbackMutation(
  baseOptions?: MutationHookOptions<
    AddRemoteInteractionFeedbackMutation,
    AddRemoteInteractionFeedbackMutationVariables
  >
) {
  const [addRemoteInteractionFeedback, status] =
    useAddRemoteInteractionFeedbackMutationApollo({
      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      AddRemoteInteractionFeedbackMutation,
      AddRemoteInteractionFeedbackVariables
    >
  ) => {
    const defaultVariables = {
      // placeholders, have to be overwritten when calling the mutate function
      id: '',
      rating: 0,
      attendeeId: undefined,
      content: undefined,
    };

    return addRemoteInteractionFeedback({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
