import { SYSTEM, VIDEO } from '@/config';

const DEV_REALM = SYSTEM.REALM || 'isarlife-de';

export const isLocalHost = (): boolean => {
  const { hostname, href } = window.location;

  const host = hostname.split('.')?.[0] || hostname;
  const hasLocalHost = !!['localhost', '10', '127', '172', '192'].find(
    (it) => it === host
  );

  const ngrokMatcher = new RegExp(/^https:\/\/ngrok\.dev\.ysura\.com\//);
  const hasNgrokHostname = ngrokMatcher.test(href);

  return hasLocalHost || (SYSTEM.IS_DEV && hasNgrokHostname);
};

/**
 * In case of local development retrieve the realm from the environment
 * in production setup, retrieve it from the current location
 * (e.g. https://isarlife-de-hcp.dev.ysura.com/ => "isarlife-de")
 */
export const getRealm = (): string => {
  const { hostname } = window.location;

  const host = hostname.split('.')?.[0] || hostname;
  const realm = host.split('-hcp')?.[0] || host;

  return isLocalHost() ? DEV_REALM : realm;
};

/**
 * Gets the URL of the state-server from the environment variables
 */
export const getStateServerUrl = (): string => {
  return VIDEO.STATE_SERVER_URL || 'http://localhost:3010';
};

/**
 * Gets the URL of the WebAPI. If VITE_API_URL is set, it will be used,
 * otherwise it will be constructed from the realm and  VITE_API_HOST.
 */
export const getApiUrl = () => {
  if (SYSTEM?.API_URL?.length > 0) {
    return SYSTEM.API_URL;
  }

  const realm = getRealm();

  return `https://${realm}-api.${SYSTEM.API_HOST}/anon/v1/graphql`;
};
