import { InteractionItemsColumn } from '@ysura/common';

import { SampleRequestCollection } from '@/components/RemoteInteraction/PromotionalMaterial';

export const SampleRequestCollectionColumn = () => {
  return (
    <InteractionItemsColumn
      isSharing
      flex={2}
      testId="promats-collection-column"
    >
      <SampleRequestCollection />
    </InteractionItemsColumn>
  );
};
