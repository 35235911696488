import { Loader, useLocalStorage } from '@ysura/common';
import { useEffect, useState } from 'react';

import { useInteraction } from '@/hooks';
import { parseParticipants } from '@/services';
import {
  useGetRoomByCodeLazyQuery,
  useGetRoomByIdLazyQuery,
} from '@/services/graphql';
import { ActivityTypeBaseType } from '@/services/graphql/types/types';
import { addAttendeeInfoToSentry } from '@/utils';
import { RemoteInteraction, TelephoneInteraction } from '@/views/Interaction';

import { NotFound404 } from '../NotFound404';

export const Room = () => {
  const [isRoomValid, setIsRoomValid] = useState(false);
  const [attendeeNodeId, setAttendeeNodeId] = useLocalStorage<string>(
    'attendeeNodeId',
    ''
  );

  const {
    roomId,
    roomCode,
    interactionMode,
    attendee,
    organizer,
    setAttendee,
    setRoomId,
    setOrganizer,
    setCoOrganizers,
    setIsScreenSharingEnable,
    setInteractionMode,
  } = useInteraction();

  const [getRoomByIdAndAttendee, { loading: isRoomByIdLoading }] =
    useGetRoomByIdLazyQuery();
  const [getRoomByCode, { loading: isRoomByCodeLoading }] =
    useGetRoomByCodeLazyQuery();

  useEffect(() => {
    // Get room id, attendee id from url
    if (roomId && attendeeNodeId) {
      getRoomByIdAndAttendee({
        variables: {
          id: roomId,
          attendeeId: attendeeNodeId,
        },
      }).then((res) => {
        if (res.data && res.data.anonRemoteInteraction) {
          const activity = res.data.anonRemoteInteraction;

          setRoomId(activity.id);

          const { apiOrganizer, apiAttendee, apiCoOrganizers } =
            parseParticipants(activity);

          if (apiAttendee) {
            setAttendee(apiAttendee);
            addAttendeeInfoToSentry(apiAttendee);
          }

          apiOrganizer && setOrganizer(apiOrganizer);
          apiCoOrganizers && setCoOrganizers(apiCoOrganizers);

          setIsScreenSharingEnable(activity.attendeeScreenSharingAllowed);

          if (activity.baseType === ActivityTypeBaseType.Telephone) {
            setInteractionMode('phone');
          } else if (activity.baseType === ActivityTypeBaseType.Remote) {
            setInteractionMode('remote');
          }

          setIsRoomValid(true);
        } else {
          setIsRoomValid(false);
        }
      });
    }

    // if room code is present, ...
    if (roomCode) {
      //we have checked the code and loaded the information before ...
      if (roomId && organizer?.personOid && attendee?.personOid) {
        setIsRoomValid(true);
      }
      // ... or we are in a reload situation and need to load the information again.
      else {
        getRoomByCode({
          variables: {
            code: roomCode,
          },
        }).then((res) => {
          if (res.data && res.data.anonRemoteInteractionByCode) {
            const activity = res.data.anonRemoteInteractionByCode;

            setRoomId?.(activity.id);

            const { apiOrganizer, apiAttendee, apiCoOrganizers } =
              parseParticipants(activity);

            if (apiAttendee) {
              setAttendee?.(apiAttendee);
              addAttendeeInfoToSentry(apiAttendee);
            }

            apiOrganizer && setOrganizer?.(apiOrganizer);
            apiCoOrganizers && setCoOrganizers?.(apiCoOrganizers);

            setIsScreenSharingEnable?.(activity.attendeeScreenSharingAllowed);
            if (activity.baseType === ActivityTypeBaseType.Telephone) {
              setInteractionMode('phone');
            } else if (activity.baseType === ActivityTypeBaseType.Remote) {
              setInteractionMode('remote');
            }

            setIsRoomValid(true);
          } else {
            setIsRoomValid(false);
          }
        });
      }
    }
  }, [
    roomId,
    attendeeNodeId,
    roomCode,
    getRoomByIdAndAttendee,
    setIsScreenSharingEnable,
    setRoomId,
    getRoomByCode,
    setAttendeeNodeId,
    setOrganizer,
    setAttendee,
    organizer?.personOid,
    attendee?.personOid,
    setCoOrganizers,
    setInteractionMode,
  ]);

  if (isRoomByIdLoading || isRoomByCodeLoading) {
    return <Loader />;
  }

  if (!isRoomValid) {
    return <NotFound404 />;
  }

  return interactionMode === 'phone' ? (
    <TelephoneInteraction />
  ) : interactionMode === 'remote' ? (
    <RemoteInteraction />
  ) : null;
};
