import { PageContainer } from '@ysura/common';

import { InteractionHeader } from '@/views/Interaction/Common';
import { TelephoneWaitingRoomContent } from '@/views/Interaction/Telephone/WaitingRoom';

type TelephoneWaitingRoomProps = {
  handleStartInteraction: VoidFunction;
};

export const TelephoneWaitingRoom = ({
  handleStartInteraction,
}: TelephoneWaitingRoomProps) => {
  return (
    <>
      <PageContainer>
        {/* InteractionHeader header is only visible on desktop */}
        <InteractionHeader />

        {/* Content is always visible */}
        <TelephoneWaitingRoomContent
          handleStartInteraction={handleStartInteraction}
        />
      </PageContainer>
    </>
  );
};
