import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import type { HomepageStep } from '@/pages/Homepage';

import { Footer } from './Footer';
import { HomepageStaticBlock } from './HomepageStaticBlock';

type HomepageContainerProps = {
  homepageStep: HomepageStep;
  children: ReactNode;
};

export const HomepageContainer = ({
  homepageStep,
  children,
}: HomepageContainerProps) => {
  return (
    <Container data-testid="homepage-container">
      <HomepageStaticBlock homepageStep={homepageStep}>
        {children}
      </HomepageStaticBlock>

      <Footer />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',

  padding: 0,

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      padding: 0,
    },
}));
