import { CancelPresentation, PresentToAll } from '@mui/icons-material';
import { Button as MuiButton, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isMobileDevice, RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

type ScreenShareButtonProps = {
  variant: 'round' | 'list';
  shouldBroadcast?: boolean;
};

export const ScreenShareButton = ({
  variant,
  shouldBroadcast,
}: ScreenShareButtonProps) => {
  const { t } = useTranslation();
  const {
    isScreenSharingEnable,
    isAnyContentShared,
    isOwnScreenShared,
    startScreenSharing,
    stopScreenSharing,
  } = useInteraction();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const buttonSize = isSmallScreen ? 'medium' : 'large';

  // Mobile devices don't support screen sharing
  const isMobile = isMobileDevice();
  if (!isScreenSharingEnable || isMobile) {
    return null;
  }

  const roundButtonVariant = isOwnScreenShared ? 'primary' : 'secondary';

  const handleToggleScreenShare = () => {
    if (isOwnScreenShared) {
      stopScreenSharing();
    } else {
      startScreenSharing(shouldBroadcast);
    }
  };

  if (variant === 'list') {
    return (
      <ListButton
        disabled={isAnyContentShared}
        startIcon={<PresentToAll />}
        onClick={handleToggleScreenShare}
      >
        {isOwnScreenShared
          ? t('pages.room.screenSharing.startedSharing')
          : t('pages.room.screenSharing.stoppedSharing')}
      </ListButton>
    );
  }

  return (
    <RoundButton
      testId="share-screen-button"
      variant={roundButtonVariant}
      size={buttonSize}
      disabled={isAnyContentShared}
      onClick={handleToggleScreenShare}
    >
      {isOwnScreenShared ? <CancelPresentation /> : <PresentToAll />}
    </RoundButton>
  );
};

const ListButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'none',
}));
