import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  Box,
  Button,
  Rating,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocalStorage } from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddRemoteInteractionFeedbackMutation } from '@/hooks';

import { FeedbackStep } from '../Feedback';

type FeedbackRatingProps = {
  handleChangeStep: (val: FeedbackStep) => void;
  roomId: string;
};

const labels = [
  'pages.feedback.rateIt',
  'pages.feedback.bad',
  'pages.feedback.poor',
  'pages.feedback.average',
  'pages.feedback.great',
  'pages.feedback.excellent',
];

export const FeedbackRating = ({
  handleChangeStep,
  roomId,
}: FeedbackRatingProps) => {
  const { t } = useTranslation();
  const [addRemoteInteractionFeedbackMutation] =
    useAddRemoteInteractionFeedbackMutation();
  const [ratingValue, setRatingValue] = useState<number | null>(null);
  const [feedbackText, setFeedbackText] = useState('');

  const [attendeeNodeId] = useLocalStorage<string>('attendeeNodeId', '');

  const handleSubmitFeedback = async () => {
    addRemoteInteractionFeedbackMutation({
      variables: {
        id: roomId,
        attendeeId: attendeeNodeId,
        rating: ratingValue ?? 0,
        content: feedbackText,
      },
      onCompleted: () => {
        handleChangeStep('thankFeedback');
      },
      onError: () => {
        handleChangeStep('thankFeedback');
      },
    });
  };

  const handleDismiss = () => {
    handleChangeStep('thankAttending');
  };

  const onChangeFeedbackText = (event: ChangeEvent<HTMLInputElement>) => {
    setFeedbackText(event.target.value);
  };

  const shouldDisableSubmitButton = !ratingValue && !feedbackText;

  return (
    <Wrapper data-testid="feedback-rating-wrapper">
      <Typography variant="h5">{t('pages.feedback.howWasYourCall')}</Typography>
      <RatingBox>
        <Rating
          data-testid="rating-star"
          name="text-feedback"
          value={ratingValue}
          precision={1}
          size="large"
          emptyIcon={<StyledStarRoundedIcon fontSize="inherit" />}
          icon={<StarRoundedIcon fontSize="inherit" />}
          onChange={(event, newValue) => {
            setRatingValue(newValue);
          }}
        />
        <Typography variant="caption">{t(labels[ratingValue || 0])}</Typography>
      </RatingBox>

      <TextField
        placeholder={t('pages.feedback.feedbackPlaceholder')}
        value={feedbackText}
        onChange={onChangeFeedbackText}
      />

      <MediumBox>
        <SubmitButton
          variant="contained"
          size="large"
          disabled={shouldDisableSubmitButton}
          onClick={handleSubmitFeedback}
        >
          {t('pages.feedback.submitNow')}
        </SubmitButton>
        <DismissButton variant="text" size="large" onClick={handleDismiss}>
          {t('pages.feedback.dismiss')}
        </DismissButton>
      </MediumBox>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  justifyContent: 'flex-start',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));

const RatingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledStarRoundedIcon = styled(StarRoundedIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const MediumBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const SubmitButton = styled(Button)({
  textTransform: 'uppercase',
});

const DismissButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
