import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button as MuiButton, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isMobileDevice, MenuPopover } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

type SeeMoreProps = {
  isPopoverOpen: boolean;
  shouldBroadcast?: boolean;
  anchorEl: HTMLElement | null;
  closePopover: VoidFunction;
};

export const SeeMore = ({
  isPopoverOpen,
  shouldBroadcast,
  anchorEl,
  closePopover,
}: SeeMoreProps) => {
  const {
    setIsVideoSettingsDialogOpen,
    isAnyContentShared,
    isScreenSharingEnable,
    isOwnScreenShared,
    startScreenSharing,
    stopScreenSharing,
  } = useInteraction();
  const { t } = useTranslation();

  // Mobile devices don't support screen sharing
  const allowScreenSharing = isScreenSharingEnable && !isMobileDevice();

  const handleOpenSettings = () => {
    setIsVideoSettingsDialogOpen?.(true);
    closePopover();
  };

  const handleToggleScreenShare = () => {
    if (isOwnScreenShared) {
      stopScreenSharing();
    } else {
      startScreenSharing(shouldBroadcast);
    }
  };

  const handleOpenShareScreen = () => {
    handleToggleScreenShare();
    closePopover();
  };

  return (
    <Popover
      disabledArrow
      open={isPopoverOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={closePopover}
    >
      {allowScreenSharing && (
        <>
          <Button
            startIcon={<PresentToAllIcon />}
            disabled={isAnyContentShared}
            onClick={handleOpenShareScreen}
          >
            {t('pages.room.audioVideoSettings.shareScreen')}
          </Button>
          <Divider />
        </>
      )}
      <Button startIcon={<Settings />} onClick={handleOpenSettings}>
        {t('pages.room.audioVideoSettings.settings')}
      </Button>
    </Popover>
  );
};

const Settings = styled(SettingsIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Popover = styled(MenuPopover)({
  '.MuiPopover-paper': {
    width: 'fit-content',
  },
});

const Button = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'none',
  width: '100%',
  justifyContent: 'flex-start',
}));
