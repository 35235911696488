import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CUSTOM_BREAKPOINTS, InteractionItemsColumn } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

export const ScreenShareColumn = () => {
  const { t } = useTranslation();
  const { organizer, isParticipantsScreenShared } = useInteraction();

  return (
    // Hide the column if isScreenSharing = false, because this component is always rendered (see InteractionContent.tsx)
    <InteractionItemsColumn
      isHidden={!isParticipantsScreenShared}
      flex={2}
      testId="screen-share-column"
    >
      <Container>
        <Title variant="h5">
          {t('pages.room.screenSharing.participantStartedSharing', {
            displayName: organizer?.displayName,
          })}
        </Title>

        <ScreenShare
          id="participantScreenShare"
          data-testid="participantScreenShare"
        />
      </Container>
    </InteractionItemsColumn>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const ScreenShare = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['700'],
  borderRadius: theme.shape.borderRadius,
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',

  video: {
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));
