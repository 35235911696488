import { Mic, MicOff } from '@mui/icons-material';
import { RoundButton } from '@ysura/common';

import { useInteraction } from '@/hooks';

type ToggleCameraButtonProps = {
  size?: 'large' | 'medium' | 'small';
};

export const ToggleMicButton = ({
  size = 'large',
}: ToggleCameraButtonProps) => {
  const { isMicActive, microphonePermissionState, setIsMicActive } =
    useInteraction();

  const toggleMic = () => {
    setIsMicActive?.(!isMicActive);
  };

  return (
    <RoundButton
      size={size}
      variant="secondary"
      inactive={!isMicActive}
      disabled={microphonePermissionState !== 'granted'}
      testId="mic-button"
      onClick={toggleMic}
    >
      {microphonePermissionState === 'granted' && isMicActive ? (
        <Mic />
      ) : (
        <MicOff />
      )}
    </RoundButton>
  );
};
