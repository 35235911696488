import { styled } from '@mui/material/styles';
import { PageOverlay, varFade } from '@ysura/common';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type InteractionAnimateProps = {
  children: ReactNode;
};

export const InteractionAnimate = ({ children }: InteractionAnimateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <PageOverlay
        key="interaction-room-overlay"
        animate="enter"
        overlayText={t('components.transition.joiningCall')}
      />

      <AnimateBox
        key="interaction-room"
        {...varFade({ delay: 0.4, durationIn: 0.4, easeIn: 'easeIn' }).in}
      >
        {children}
      </AnimateBox>
    </>
  );
};

const AnimateBox = styled(motion.div)({
  width: '100%',
  height: '100%',
});
