import { gql } from '@apollo/client';

export const getRoomById = gql`
  query getRoomById($id: ID!, $attendeeId: ID) {
    anonRemoteInteraction(id: $id, attendeeId: $attendeeId) {
      id
      startDate
      endDate
      startDateTime
      endDateTime
      participants {
        id
        name
        role
      }
      attendeeScreenSharingAllowed
      baseType
    }
  }
`;
