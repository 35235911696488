import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';
import { PermissionState } from '@/hooks/useInteraction';

type MessageProps = {
  permission: PermissionState;
  hardwareItem: 'camera' | 'microphone';
};

export const PermissionMessages = () => {
  const { t } = useTranslation();
  const {
    microphonePermissionState,
    cameraPermissionState,
    setMicrophonePermissionState,
    setCameraPermissionState,
    arePermissionsChecked,
  } = useInteraction();

  const handleGetUserMedia = (hardwareItem: string) => {
    if (hardwareItem === 'camera') {
      navigator.mediaDevices
        ?.getUserMedia({ video: true })
        .then(() => {
          setCameraPermissionState?.('granted');
        })
        .catch(() => {
          setCameraPermissionState?.('denied');
        });
    }

    if (hardwareItem === 'microphone') {
      navigator.mediaDevices
        ?.getUserMedia({ audio: true })
        .then(() => {
          setMicrophonePermissionState?.('granted');
        })
        .catch(() => {
          setMicrophonePermissionState?.('denied');
        });
    }
  };

  const getDeniedMessage = ({ permission, hardwareItem }: MessageProps) => {
    if (permission === 'denied') {
      return (
        <InfoMessage variant="body2">
          {t('pages.room.permissionMessages.partlyBlocked', {
            hardwareItem: t(`pages.room.permissionMessages.${hardwareItem}`),
          })}
        </InfoMessage>
      );
    }

    return '';
  };

  const getPendingMessage = ({ permission, hardwareItem }: MessageProps) => {
    if (permission === 'pending') {
      const useHardware = t('pages.room.permissionMessages.useHardware', {
        hardwareItem: t(`pages.room.permissionMessages.${hardwareItem}`),
      });

      const confirmChoice = t('pages.room.permissionMessages.confirmChoice');

      return (
        <InfoMessage variant="body2">
          {useHardware}{' '}
          <ClickHere onClick={() => handleGetUserMedia(hardwareItem)}>
            {t('pages.room.permissionMessages.clickHere')}
          </ClickHere>
          {'. '}
          {confirmChoice}
        </InfoMessage>
      );
    }

    return '';
  };

  if (!arePermissionsChecked) {
    return <Loader />;
  }

  // ---> If permissions for both mic and camera were denied, render the error message and return

  if (
    microphonePermissionState === 'denied' &&
    cameraPermissionState === 'denied'
  ) {
    return (
      <ErrorMessage variant="body2">
        {t('pages.room.permissionMessages.fullyBlocked')}
      </ErrorMessage>
    );
  }

  // ---> Denied messages

  const micDeniedMessage = getDeniedMessage({
    permission: microphonePermissionState,
    hardwareItem: 'microphone',
  });

  const cameraDeniedMessage = getDeniedMessage({
    permission: cameraPermissionState,
    hardwareItem: 'camera',
  });

  const doesDeniedMessageExist = micDeniedMessage || cameraDeniedMessage;

  // ---> Pending messages

  const micPendingMessage = getPendingMessage({
    permission: microphonePermissionState,
    hardwareItem: 'microphone',
  });

  const cameraPendingMessage = getPendingMessage({
    permission: cameraPermissionState,
    hardwareItem: 'camera',
  });

  const doesPendingMessageExist = micPendingMessage || cameraPendingMessage;

  // --> Rendering

  if (doesDeniedMessageExist || doesPendingMessageExist) {
    return (
      <Wrapper data-testid="permission-messages">
        {cameraDeniedMessage}
        {micDeniedMessage}

        {cameraPendingMessage}
        {micPendingMessage}
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const InfoMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ClickHere = styled('span')({
  cursor: 'pointer',
  fontWeight: 600,

  textDecoration: 'underline',
});
