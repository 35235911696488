import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CUSTOM_BREAKPOINTS } from '@ysura/common';
import { ReactNode } from 'react';

import { HOMEPAGE_CARD_CONTENT, HOMEPAGE_FOOTER } from '@/config/layout';
import { Footer } from '@/views/Homepage/Footer';

import { FeedbackStep } from './Feedback';
import { FeedbackGraphic } from './FeedbackGraphic';
import { FeedbackStaticBlock } from './FeedbackStaticBlock';

type FeedbackContainerProps = {
  feedbackStep: FeedbackStep;
  children: ReactNode;
};

export const FeedbackContainer = ({
  children,
  feedbackStep,
}: FeedbackContainerProps) => {
  return (
    <Container data-testid="feedback-container">
      <FeedbackStaticBlock>
        <FeedbackGraphic feedbackStep={feedbackStep} />
        <ContentWrapper>{children}</ContentWrapper>
      </FeedbackStaticBlock>

      <Footer />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',

  // adding footer height to padding value to avoid text overlapping due to absolute positioning
  padding: `calc(${theme.spacing(3)} + ${
    HOMEPAGE_FOOTER.HEIGHT
  }) ${theme.spacing(3)}`,

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingBottom: `calc(${theme.spacing(4)} + ${HOMEPAGE_FOOTER.HEIGHT})`,
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: HOMEPAGE_CARD_CONTENT.WIDTH,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      justifyContent: 'center',
      width: '100%',
    },
}));
