import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PageHeaderProps {
  title: string;
}

export const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <Header variant="h5" data-testid="page-header">
      {title}
    </Header>
  );
};

const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
