import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CUSTOM_BREAKPOINTS,
  REMOTE_INTERACTION,
  RoomContentWrapper,
  WAITING_ROOM,
} from '@ysura/common';
import { useState } from 'react';

import { useInteraction } from '@/hooks';
import { WaitingRoomCallInfo } from '@/views/Interaction/Common';
import { VideoAndControls } from '@/views/Interaction/Remote/VideoAndControls';

type RemoteWaitingRoomContentProps = {
  handleStartInteraction: VoidFunction;
};

const DISABLING_STATUSES = ['pending', 'denied'];

export const RemoteWaitingRoomContent = ({
  handleStartInteraction,
}: RemoteWaitingRoomContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { microphonePermissionState, cameraPermissionState } = useInteraction();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const isButtonDisabled =
    DISABLING_STATUSES.includes(microphonePermissionState) &&
    DISABLING_STATUSES.includes(cameraPermissionState);

  return (
    <RoomContentWrapper>
      <VideoAndControls />

      {isMobile ? (
        <ExtendableBox isDrawerOpen={isDrawerOpen}>
          <IconButton onClick={toggleDrawer}>
            {isDrawerOpen ? (
              <KeyboardArrowDown fontSize="small" />
            ) : (
              <KeyboardArrowUp fontSize="small" />
            )}
          </IconButton>

          <WaitingRoomCallInfo
            handleStartInteraction={handleStartInteraction}
            isButtonDisabled={isButtonDisabled}
          />
        </ExtendableBox>
      ) : (
        <WaitingRoomCallInfo
          handleStartInteraction={handleStartInteraction}
          isButtonDisabled={isButtonDisabled}
        />
      )}
    </RoomContentWrapper>
  );
};

type ExtendableProps = {
  isDrawerOpen: boolean;
};

const ExtendableBox = styled(Box, {
  shouldForwardProp: (prop) => !(prop === 'isDrawerOpen'),
})<ExtendableProps>(({ isDrawerOpen, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: isDrawerOpen ? '100%' : WAITING_ROOM.MOBILE.DRAWER_HEIGHT_PORTRAIT,
  background: theme.palette.background.neutral,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
      height: isDrawerOpen
        ? '100%'
        : REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE,
    },
}));
