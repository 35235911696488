import { useMediaQuery, useTheme } from '@mui/material';
import {
  ConsentCollectionColumn,
  MediaSharingColumn,
  PageContainer,
  SimpleDialog,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentCollection, PDFMedia } from '@/components/RemoteInteraction';
import { useInteraction } from '@/hooks';
import {
  InteractionHeader,
  SampleRequestCollectionColumn,
  ScreenShareColumn,
} from '@/views/Interaction/Common';
import {
  CallContentInfo,
  CallContentWrapper,
  CallStatusContentActions,
} from '@/views/Interaction/Telephone/Interaction';

type TelephoneCallContentProps = {
  handleLeaveInteraction: VoidFunction;
};

export const TelephoneCallContent = ({
  handleLeaveInteraction,
}: TelephoneCallContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    sharingConsent,
    isMediaShared,
    isAnyContentShared,
    isParticipantsScreenShared,
    sharingSampleRequest,
    sharingMedia,
    broadcastPointerShow,
    onMediaStateChanged,
    onPointerShown,
    onScreenShareStarted,
  } = useInteraction();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (sharingConsent) {
      setFormKey((currentValue) => currentValue + 1);
    }
  }, [sharingConsent]);

  useEffect(() => {
    const unsubscribeOnScreenShareStarted = onScreenShareStarted();

    return () => unsubscribeOnScreenShareStarted?.();
  }, [onScreenShareStarted]);

  return (
    <PageContainer data-testid="telephone-call-content">
      {/* Room header is only visible on desktop */}
      <InteractionHeader />

      {/* Content is always visible */}
      <CallContentWrapper>
        {/* Left action buttons */}
        <CallStatusContentActions
          isMobile={isMobile}
          leaveInteraction={() => setIsDialogOpen(true)}
        />

        {/* Nothing is being shared, so main content is displayed */}
        {!isAnyContentShared && !isParticipantsScreenShared && (
          <CallContentInfo isMobile={isMobile} />
        )}

        {/* Media is being shard */}
        {isMediaShared && sharingMedia?.media && (
          <MediaSharingColumn
            media={sharingMedia?.media}
            broadcastPointerShow={broadcastPointerShow}
            PdfMediaComponent={
              <PDFMedia
                media={sharingMedia?.media}
                initialPage={sharingMedia?.initialPage?.indexh}
              />
            }
            onMediaStateChanged={onMediaStateChanged}
            onPointerShown={onPointerShown}
          />
        )}

        {/* With the formKey we make sure the rendered iframe is refreshed every time a new form is shared */}
        {sharingConsent && (
          <ConsentCollectionColumn key={formKey}>
            <ConsentCollection />
          </ConsentCollectionColumn>
        )}

        {/* A request is being shared */}
        {sharingSampleRequest && <SampleRequestCollectionColumn />}

        {/*
        This component must be unconditionally always rendered to give the video server
        a place to render the screen share video stream
        */}
        <ScreenShareColumn />
      </CallContentWrapper>

      <SimpleDialog
        isOpen={isDialogOpen}
        testId={'end-interaction-dialog'}
        title={t('pages.room.leaveQuestion')}
        closeDialogText={t('pages.room.leave')}
        closeDialog={handleLeaveInteraction}
        cancelDialogText={t('components.common.cancel')}
        cancelDialog={() => setIsDialogOpen(false)}
      />
    </PageContainer>
  );
};
