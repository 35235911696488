export const SENTRY = {
  ENABLED: 'true' === (import.meta.env.VITE_SENTRY_ENABLED as string),
  DSN: import.meta.env.VITE_SENTRY_DSN as string,
  ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT as string,
  GRAPHQL_BREADCRUMB_ENRICHMENT:
    'true' ===
    (import.meta.env.VITE_SENTRY_GRAPHQL_BREADCRUMB_ENRICHMENT as string),
  SESSION_REPLAY:
    'true' === (import.meta.env.VITE_SENTRY_SESSION_REPLAY as string),
  USER_ENRICHMENT:
    'true' === (import.meta.env.VITE_SENTRY_USER_ENRICHMENT as string),
};
