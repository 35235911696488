import { Loader, useLocalStorage } from '@ysura/common';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useInteraction, usePermissionCheck } from '@/hooks';
import { HomepageContainer, Permissions, RoomCodeForm } from '@/views/Homepage';

export type HomepageStep = 'roomId' | 'permissions';

export const Homepage = () => {
  const [homepageStep, setHomepageStep] = useState<HomepageStep>('roomId');
  const [, setAttendeeNodeId] = useLocalStorage<string>('attendeeNodeId', '');

  const { setRoomId, setRoomCode } = useInteraction();

  const [searchParams] = useSearchParams();
  const roomIdInUrl = searchParams.get('room') ?? '';
  const attendeeIdUrl = searchParams.get('attendee') ?? '';
  const { isRunningPermissionsCheck, checkPermissions } = usePermissionCheck();
  const navigate = useNavigate();

  useEffect(() => {
    if (attendeeIdUrl) {
      // We store the nodeId to be used in the graphQL call
      setAttendeeNodeId(attendeeIdUrl);
    }

    if (roomIdInUrl) {
      setRoomId?.(roomIdInUrl);
      navigate(`/room/${roomIdInUrl}`);
    } else {
      setRoomId?.('');
      setRoomCode?.('');
    }
  }, [
    attendeeIdUrl,
    roomIdInUrl,
    setRoomId,
    navigate,
    setAttendeeNodeId,
    setRoomCode,
  ]);

  useEffect(() => {
    if (homepageStep === 'permissions') {
      checkPermissions();
    }
  }, [checkPermissions, homepageStep]);

  const mainContent = () => {
    if (isRunningPermissionsCheck && homepageStep === 'permissions') {
      return <Loader />;
    }

    if (homepageStep === 'roomId' && !roomIdInUrl) {
      return (
        // STEP 1: Room Id form (if no roomId in the URL)
        <RoomCodeForm onChangeHomepageStep={setHomepageStep} />
      );
    }
    if (homepageStep === 'permissions') {
      return (
        // STEP 2: Permissions (after room code has been validated)
        <Permissions />
      );
    }
  };

  return (
    <HomepageContainer homepageStep={homepageStep}>
      {mainContent()}
    </HomepageContainer>
  );
};
