import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';

import { SharingStateProps } from './SharingStateProps';
import { Video } from './Video';
import { VideoControls } from './VideoControls';
import { VideoControlsMobile } from './VideoControlsMobile';

type InteractionVideoAndControlsProps = {
  openLeaveInteractionDialog: VoidFunction;
  showMoreButton: boolean;
};

export const InteractionVideoAndControls = ({
  openLeaveInteractionDialog,
  showMoreButton,
}: InteractionVideoAndControlsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isMediaShared, isAnyContentShared, isParticipantsScreenShared } =
    useInteraction();

  return (
    <VideosControlContainer
      data-testid="videos-and-control-container"
      isMediaOrScreenShared={isMediaShared || isParticipantsScreenShared}
      isAnyContentShared={isAnyContentShared}
    >
      <Video />

      {/* These controls will not be visible for mobile landscape */}
      <ControlsWrapper data-testid="non-mobile-landscape-controls">
        {isMobile ? (
          <VideoControlsMobile
            openLeaveInteractionDialog={openLeaveInteractionDialog}
          />
        ) : (
          <VideoControls
            openLeaveInteractionDialog={openLeaveInteractionDialog}
            showMoreButton={showMoreButton}
          />
        )}
      </ControlsWrapper>
    </VideosControlContainer>
  );
};

const VideosControlContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isMediaOrScreenShared' && prop !== 'isAnyContentShared',
})<SharingStateProps>(({
  theme,
  isMediaOrScreenShared,
  isAnyContentShared,
}) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,

    borderRadius: Number(theme.shape.borderRadius) * 2,
    background: theme.palette.grey[800],

    gap: theme.spacing(2),
    padding: isMediaOrScreenShared
      ? `${theme.spacing(3)} ${theme.spacing(1)}`
      : theme.spacing(1),
    paddingBottom: isMediaOrScreenShared ? theme.spacing(3) : theme.spacing(2),

    maxWidth: isMediaOrScreenShared
      ? REMOTE_INTERACTION.DESKTOP.VIDEO_CONTAINER_FULLSCREEN_WIDTH
      : '100%',
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      borderRadius: 0,
      maxWidth: '100%',
      height: isAnyContentShared
        ? REMOTE_INTERACTION.MOBILE.VIDEO_AND_CONTROLS_HEIGHT_PORTRAIT
        : '100%',
      flex: isAnyContentShared ? 'initial' : 1,
    },

    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        padding: theme.spacing(1),
        borderRadius: 0,
        display: isAnyContentShared ? 'none' : 'flex',
      },
  };
});

const ControlsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(1)}`,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));
