import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CUSTOM_BREAKPOINTS } from '@ysura/common';
import { ReactNode } from 'react';

type VideoColumnProps = {
  variant?: 'dark' | 'light';
  flex: number;
  children: ReactNode;
};

export const VideoColumn = ({
  variant = 'light',
  flex,
  children,
}: VideoColumnProps) => {
  return (
    <Wrapper flex={flex} variant={variant} data-testid="video-column">
      {children}
    </Wrapper>
  );
};

type WrapperStyleProps = {
  flex: number;
  variant: 'dark' | 'light';
};

const Wrapper = styled(Box)<WrapperStyleProps>(({ flex, theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',

  /**
   * For waiting room: Video "column" will have flex 2, Content "column" flex 1
   * For remote room: TBD
   */
  flex,

  maxWidth: '100%',

  padding: theme.spacing(2),
  gap: theme.spacing(2),

  height: '100%',

  borderRadius: Number(theme.shape.borderRadius) * 2,
  background:
    variant === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,

  [theme.breakpoints.down('md')]: {
    flex: '4',
    borderRadius: 'initial',

    background: theme.palette.grey[800],

    padding: 0,
    gap: theme.spacing(1),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      overflow: 'hidden',
    },
}));
