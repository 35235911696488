import { PageContainer } from '@ysura/common';

import { InteractionHeader } from '@/views/Interaction/Common';

import { VideoSettingsDialog } from '../VideoSettingsDialog';
import { LandscapeVideoControls } from './LandscapeVideoControls';
import { RemoteWaitingRoomContent } from './RemoteWaitingRoomContent';

type RemoteWaitingRoomProps = {
  handleStartInteraction: VoidFunction;
};

export const RemoteWaitingRoom = ({
  handleStartInteraction,
}: RemoteWaitingRoomProps) => {
  return (
    <>
      <PageContainer>
        {/* InteractionHeader header is only visible on desktop */}
        <InteractionHeader />

        {/* This controls component is only visible on mobile landscape */}
        <LandscapeVideoControls />

        {/* Content is always visible */}
        <RemoteWaitingRoomContent
          handleStartInteraction={handleStartInteraction}
        />
      </PageContainer>

      <VideoSettingsDialog />
    </>
  );
};
