import SettingsIcon from '@mui/icons-material/Settings';
import { RoundButton } from '@ysura/common';

import { useInteraction } from '@/hooks';

type ToggleSettingsButtonProps = {
  size?: 'large' | 'medium' | 'small';
};

export const ToggleSettingsButton = ({
  size = 'large',
}: ToggleSettingsButtonProps) => {
  const { setIsVideoSettingsDialogOpen } = useInteraction();

  return (
    <RoundButton
      variant="secondary"
      size={size}
      testId="settings-button"
      onClick={() => {
        setIsVideoSettingsDialogOpen?.(true);
      }}
    >
      <SettingsIcon />
    </RoundButton>
  );
};
