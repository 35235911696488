import { Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ColorSchema = 'info' | 'success' | 'warning' | 'error';

export const useNotification = () => {
  const contextValues = useContext(NotificationContext);

  return {
    ...contextValues,
  };
};

export type BannerOptions = {
  id?: string;
  type?: ColorSchema;
  message?: ReactElement | string;
};

export type ToastOptions = {
  type?: ColorSchema;
  message?: ReactNode | string;
};

type Toast = ({ message, type }: ToastOptions) => void;

type NotificationContextValue = {
  bannerOptionsList: Array<BannerOptions>;
  toast?: Toast;
};

export const NotificationContext = createContext<NotificationContextValue>({
  bannerOptionsList: [],
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bannerOptionsList] = useState<Array<BannerOptions>>([]);

  const toast: Toast = useCallback(
    (options) => {
      enqueueSnackbar(options?.message, {
        variant: options?.type,
        content: (key) => (
          <Alert key={key} severity={options?.type ?? 'success'}>
            {options?.message}
          </Alert>
        ),
      });
    },
    [enqueueSnackbar]
  );

  const value = useMemo(
    () => ({
      bannerOptionsList,
      toast,
    }),
    [bannerOptionsList, toast]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
