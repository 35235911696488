import { Image } from '@ysura/common';

import AttendeePermission from '@/assets/homepage/attendee-permission.svg';
import AttendeeWelcome from '@/assets/homepage/attendee-welcome.svg';
import { HomepageStep } from '@/pages/Homepage';

export const HomepageGraphic = ({
  homepageStep,
}: {
  homepageStep: HomepageStep;
}) => {
  return (
    <Image
      visibleByDefault
      src={
        homepageStep === 'permissions' ? AttendeePermission : AttendeeWelcome
      }
      data-testid="homepage-graphic"
      objectFit="contain"
    />
  );
};
