import * as Apollo from '@apollo/client';

import * as Operations from '../queries';
import type * as Types from '../types/types';

const defaultOptions = {} as const;
export type GetRoomByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;

export type GetRoomByCodeQuery = {
  __typename?: 'Query';
  anonRemoteInteractionByCode?: {
    __typename?: 'AnonRemoteInteraction';
    id: string;
    startDate?: string | null;
    endDate?: string | null;
    startDateTime?: string | null;
    endDateTime?: string | null;
    attendeeScreenSharingAllowed: boolean;
    baseType: Types.ActivityTypeBaseType;
    participants: Array<{
      __typename?: 'AnonRemoteInteractionParticipant';
      id: string;
      name?: string | null;
      role: Types.AnonRemoteInteractionParticipantRole;
    }>;
  } | null;
};

/**
 * __useGetRoomByCodeQuery__
 *
 * To run a query within a React component, call `useGetRoomByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetRoomByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomByCodeQuery,
    GetRoomByCodeQueryVariables
  > &
    (
      | { variables: GetRoomByCodeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetRoomByCodeQuery, GetRoomByCodeQueryVariables>(
    Operations.getRoomByCode,
    options
  );
}
export function useGetRoomByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomByCodeQuery,
    GetRoomByCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetRoomByCodeQuery, GetRoomByCodeQueryVariables>(
    Operations.getRoomByCode,
    options
  );
}
export function useGetRoomByCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRoomByCodeQuery,
    GetRoomByCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetRoomByCodeQuery,
    GetRoomByCodeQueryVariables
  >(Operations.getRoomByCode, options);
}
export type GetRoomByCodeQueryHookResult = ReturnType<
  typeof useGetRoomByCodeQuery
>;
export type GetRoomByCodeLazyQueryHookResult = ReturnType<
  typeof useGetRoomByCodeLazyQuery
>;
export type GetRoomByCodeSuspenseQueryHookResult = ReturnType<
  typeof useGetRoomByCodeSuspenseQuery
>;
export type GetRoomByCodeQueryResult = Apollo.QueryResult<
  GetRoomByCodeQuery,
  GetRoomByCodeQueryVariables
>;
