import { useCallback } from 'react';

import { useInteraction } from '@/hooks';

export const useMediaDevices = () => {
  const {
    arePermissionsChecked,
    selectedAudioInput,
    selectedVideoInput,
    cameraPermissionState,
    microphonePermissionState,
    videoDevices,
    audioInputDevices,
    setAudioInputDevices,
    setVideoDevices,
    setSelectedAudioInput,
    setSelectedVideoInput,
  } = useInteraction();

  const loadMediaDevicesIntoState = useCallback(() => {
    /**
     * If we already have lists of both the video and audioInput devices,
     * we should not run enumerateDevices method
     */
    if (videoDevices.length && audioInputDevices.length) {
      return;
    }

    if (
      arePermissionsChecked &&
      (microphonePermissionState === 'granted' ||
        cameraPermissionState === 'granted')
    ) {
      navigator.mediaDevices.enumerateDevices().then((devices = []) => {
        const videoInputList: typeof devices = [];
        const audioInputList: typeof devices = [];

        devices.forEach((device) => {
          switch (device?.kind) {
            case 'audioinput':
              audioInputList.push(device);
              break;
            case 'videoinput':
              videoInputList.push(device);
          }
        });

        if (cameraPermissionState === 'granted') {
          setVideoDevices?.(videoInputList);
        }

        if (microphonePermissionState === 'granted') {
          setAudioInputDevices?.(audioInputList);
        }

        const videoIdList = videoInputList.map((input) => input.deviceId);
        const audioIdList = audioInputList.map((input) => input.deviceId);

        /**
         * if videoInput was not selected or if selected video input is currently not
         * available (e.g. a USB camera was unplugged), we should set a new value
         * for the selectedVideoInput. Same rule applies for audioInput.
         */
        if (!selectedVideoInput || !videoIdList.includes(selectedVideoInput)) {
          setSelectedVideoInput?.(videoInputList?.[0]?.deviceId ?? '');
        }

        if (!selectedAudioInput || !audioIdList.includes(selectedAudioInput)) {
          setSelectedAudioInput?.(audioInputList?.[0]?.deviceId ?? '');
        }
      });
    }
  }, [
    videoDevices.length,
    audioInputDevices.length,
    arePermissionsChecked,
    microphonePermissionState,
    cameraPermissionState,
    selectedVideoInput,
    selectedAudioInput,
    setVideoDevices,
    setAudioInputDevices,
    setSelectedVideoInput,
    setSelectedAudioInput,
  ]);

  return { loadMediaDevicesIntoState };
};
