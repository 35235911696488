import { unpackNodeId } from '@ysura/common';

import { InteractionParticipant } from '@/hooks/useInteraction';

import {
  AnonRemoteInteraction,
  AnonRemoteInteractionParticipantRole,
} from './graphql';

type InteractionParticipants = {
  apiOrganizer?: InteractionParticipant;
  apiAttendee?: InteractionParticipant;
  apiCoOrganizers: Array<InteractionParticipant>;
};

export const parseParticipants = (
  activity: AnonRemoteInteraction
): InteractionParticipants => {
  const organizer = activity.participants?.find(
    (item) => item.role === AnonRemoteInteractionParticipantRole.Organizer
  );
  const attendee = activity.participants?.find(
    (item) => item.role === AnonRemoteInteractionParticipantRole.Attendee
  );
  const apiCoOrganizers: Array<InteractionParticipant> =
    activity.participants
      ?.filter(
        (item) => item.role === AnonRemoteInteractionParticipantRole.CoOrganizer
      )
      .map((coOrganizer) => ({
        personOid: unpackNodeId(coOrganizer.id),
        displayName: coOrganizer.name ?? '',
        role: 'co-organizer',
      })) ?? [];

  return {
    apiOrganizer: organizer && {
      personOid: unpackNodeId(organizer?.id),
      displayName: organizer?.name ?? '',
      role: 'organizer',
    },
    apiAttendee: attendee && {
      personOid: unpackNodeId(attendee?.id),
      displayName: attendee?.name ?? '',
      role: 'attendee',
    },
    apiCoOrganizers,
  };
};
