import { deTranslation, enTranslation } from '@ysura/common';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './de/translation.json';
import en from './en/translation.json';

export const resources = {
  de: {
    commons: deTranslation,
    translation: de,
  },
  en: {
    commons: enTranslation,
    translation: en,
  },
} as const;

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    ns: ['commons', 'translation'],
    defaultNS: 'translation',
    resources,
  });
