import CallEndIcon from '@mui/icons-material/CallEnd';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RoundButton } from '@ysura/common';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';

import {
  ToggleCameraButton,
  ToggleMicButton,
  ToggleSettingsButton,
} from '../Buttons';

type VideoControlsMobileProps = {
  openLeaveInteractionDialog: VoidFunction;
};

export const VideoControlsMobile = ({
  openLeaveInteractionDialog,
}: VideoControlsMobileProps) => {
  return (
    <Container data-testid="video-controls-mobile">
      <ButtonWrapper>
        <ToggleCameraButton size="medium" />
        <ToggleMicButton size="medium" />
        <ToggleSettingsButton />
      </ButtonWrapper>

      <ButtonWrapper>
        <RoundButton
          testId="end-call-button"
          size="medium"
          variant="danger"
          onClick={openLeaveInteractionDialog}
        >
          <CallEndIcon />
        </RoundButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      alignItems: 'center',

      height: '100%',
      width: REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE,
      padding: `${theme.spacing(2)} 0`,
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
    },
}));
