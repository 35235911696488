import { useState } from 'react';

import { FeedbackContainer } from './FeedbackContainer';
import { FeedbackRating } from './Rating';
import { FeedbackThankYou } from './ThankYou';

export type FeedbackStep = 'rating' | 'thankFeedback' | 'thankAttending';

type FeedbackProps = {
  roomId: string;
};

export const Feedback = ({ roomId }: FeedbackProps) => {
  const [feedbackStep, setFeedbackStep] = useState<FeedbackStep>('rating');

  const handleChangeStep = (step: FeedbackStep) => {
    setFeedbackStep(step);
  };

  return (
    <FeedbackContainer feedbackStep={feedbackStep}>
      {feedbackStep === 'rating' ? (
        // STEP 1: Add rating star and feedback text
        <FeedbackRating handleChangeStep={handleChangeStep} roomId={roomId} />
      ) : (
        // STEP 2: Thank you for user's rating
        <FeedbackThankYou feedbackStep={feedbackStep} />
      )}
    </FeedbackContainer>
  );
};
