import { gql } from '@apollo/client';

export const getRoomByCode = gql`
  query getRoomByCode($code: String!) {
    anonRemoteInteractionByCode(code: $code) {
      id
      startDate
      endDate
      startDateTime
      endDateTime
      participants {
        id
        name
        role
      }
      attendeeScreenSharingAllowed
      baseType
    }
  }
`;
