import { CallEnd } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ParticipantBox, RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { ScreenShareButton } from '@/views/Interaction/Common';

type CallStatusInfoProps = {
  isMobile?: boolean;
  isFullScreen?: boolean;
  leaveInteraction: VoidFunction;
};

export const CallStatusContentActions = ({
  leaveInteraction,
  isMobile = false,
  isFullScreen = false,
}: CallStatusInfoProps) => {
  const { t } = useTranslation();
  const { attendee, organizer } = useInteraction();

  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  return (
    <Container data-testid="call-status-content-actions">
      <Line isFullScreen={isFullScreen}>
        <FullScreenWrapper>
          {!isMobile && (
            <ButtonGroup data-testid="call-status-content-actions-participants">
              <ParticipantBox
                showAsThumbnail
                dark
                attendeeName={attendee?.displayName}
                size="small"
              />
              <ParticipantBox
                showAsThumbnail
                attendeeName={organizer?.displayName}
                size="small"
              />
            </ButtonGroup>
          )}
          <ButtonGroup data-testid="call-status-content-actions-buttons">
            <ScreenShareButton variant="round" shouldBroadcast={true} />
            <RoundButtonStyled>
              {isMobile && !isSmallScreenLandscape && (
                <Typography variant="subtitle2">
                  {t('pages.telephone.call.leaveInteraction')}
                </Typography>
              )}
              <RoundButton
                size="large"
                variant="danger"
                data-testid="call-end-icon"
                onClick={() => leaveInteraction?.()}
              >
                <CallEnd />
              </RoundButton>
            </RoundButtonStyled>
          </ButtonGroup>
        </FullScreenWrapper>
      </Line>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),

  maxWidth: '64px',
  height: '100%',
  padding: theme.spacing(1),

  background: theme.palette.grey[800],
  borderRadius: Number(theme.shape.borderRadius) * 2,

  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(1),
    gap: theme.spacing(1),
    maxWidth: '100%',
    height: '70px',
  },
}));

type LineProps = {
  isFullScreen: boolean;
};
const Line = styled(Box, {
  shouldForwardProp: (prop) => ['isFullScreen'].indexOf(prop as string) === -1,
})<LineProps>(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  padding: `0 ${theme.spacing(1)}`,

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: 0,
    justifyContent: 'center',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      padding: 0,
      background: theme.palette.grey[800],
      justifyContent: 'start',
    },
}));

const FullScreenWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(1)} 0`,
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    width: '100%',
    height: 'fit-content',
    padding: 0,
  },
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    width: '100%',
  },
}));

const RoundButtonStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});
