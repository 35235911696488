import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader, ParticipantBox } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

export const CallStatusContentInfoBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { organizer, getParticipantInteractionStep } = useInteraction();

  const participantInteractionStep = getParticipantInteractionStep?.(
    organizer?.personOid ?? ''
  );

  return (
    <Container data-testid="call-status-content-info-block">
      <Wrapper>
        <AttendeeLine>
          {participantInteractionStep !== 'interaction' ? (
            <InitializedInteractionStyled>
              <Loader size={32} />
              <Typography
                variant="subtitle2"
                color={theme.palette.common.white}
              >
                {t('pages.telephone.waitingForOrganizer')}
              </Typography>
            </InitializedInteractionStyled>
          ) : (
            <>
              <ParticipantBox
                showAsThumbnail
                dark
                attendeeName={organizer?.displayName}
                size="large"
              />
              <Typography
                variant="subtitle2"
                color={theme.palette.common.white}
              >
                {t('pages.telephone.inCall', {
                  personName: organizer?.displayName,
                })}
              </Typography>
            </>
          )}
        </AttendeeLine>
      </Wrapper>
    </Container>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'shouldRenderVideo',
})(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.grey[700],

  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `0 ${theme.spacing(2)}`,
  gap: theme.spacing(5),
  width: '70%',
}));

const AttendeeLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const InitializedInteractionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
