import { ReactProps, useLocalStorage } from '@ysura/common';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  DEFAULT_FN,
  InteractionMode,
  InteractionParticipant,
  InteractionStepInfo,
  UseInteractionState,
} from './useInteractionTypes';

type InteractionStepMap = Record<string, InteractionStepInfo>;

export const useInteractionStateInitialValue: UseInteractionState = {
  roomId: '',
  roomCode: '',
  coOrganizers: [],
  interactionMode: null,

  setRoomId: DEFAULT_FN,
  setRoomCode: DEFAULT_FN,
  setAttendee: DEFAULT_FN,
  setOrganizer: DEFAULT_FN,
  setCoOrganizers: DEFAULT_FN,
  setInteractionMode: DEFAULT_FN,

  getInteractionStep: () => null,
  createOrUpdateInteractionStep: DEFAULT_FN,
  clearInteractionStepForRoomId: DEFAULT_FN,

  clearInteractionState: DEFAULT_FN,
};

const INTERACTION_STEP_KEY = 'interaction-step';

const InteractionStateContext = createContext(useInteractionStateInitialValue);

export const InteractionStateContextProvider: FC<ReactProps> = (props) => {
  const [roomId, setRoomId] = useLocalStorage('roomId', '');

  const [attendee, setAttendee] = useState<InteractionParticipant | undefined>(
    undefined
  );
  const [organizer, setOrganizer] = useState<
    InteractionParticipant | undefined
  >(undefined);
  const [coOrganizers, setCoOrganizers] = useState<
    Array<InteractionParticipant>
  >([]);
  const [roomCode, setRoomCode] = useLocalStorage('roomCode', '');
  const [interactionMode, setInteractionMode] =
    useState<InteractionMode | null>(null);

  const [interactionStepMap, setInteractionStepMap] =
    useLocalStorage<InteractionStepMap>(INTERACTION_STEP_KEY, {});

  const getInteractionStep = useCallback(
    (roomId: string | null | undefined) => {
      if (!roomId) {
        return null;
      }

      return interactionStepMap?.[roomId] ?? null;
    },
    [interactionStepMap]
  );

  const createOrUpdateInteractionStep = useCallback(
    (roomId: string, currentStep: InteractionStepInfo) => {
      setInteractionStepMap((currentMap) => {
        const newMap = { ...currentMap };
        newMap[roomId] = currentStep;

        return newMap;
      });
    },
    [setInteractionStepMap]
  );

  const clearInteractionStepForRoomId = useCallback(
    (roomId: string | null | undefined) => {
      if (!roomId) {
        return;
      }

      setInteractionStepMap((currentMap) => {
        const newMap = { ...currentMap };

        if (newMap?.[roomId]) {
          delete newMap[roomId];
        }

        return newMap;
      });
    },
    [setInteractionStepMap]
  );

  const clearInteractionState = useCallback(() => {
    clearInteractionStepForRoomId(roomId);

    setRoomId('');
    setRoomCode('');
    setAttendee(undefined);
    setOrganizer(undefined);
    setCoOrganizers([]);
    setInteractionMode(null);
  }, [clearInteractionStepForRoomId, roomId, setRoomCode, setRoomId]);

  const values = useMemo(
    () => ({
      roomId,
      roomCode,
      attendee,
      organizer,
      coOrganizers,
      interactionMode,

      setRoomId,
      setRoomCode,
      setAttendee,
      setOrganizer,
      setCoOrganizers,
      setInteractionMode,

      getInteractionStep,
      createOrUpdateInteractionStep,
      clearInteractionStepForRoomId,

      clearInteractionState,
    }),
    [
      attendee,
      clearInteractionState,
      clearInteractionStepForRoomId,
      coOrganizers,
      createOrUpdateInteractionStep,
      getInteractionStep,
      interactionMode,
      organizer,
      roomCode,
      roomId,
      setRoomCode,
      setRoomId,
    ]
  );

  return <InteractionStateContext.Provider value={values} {...props} />;
};

export const useInteractionState = () => {
  return useContext(InteractionStateContext);
};
