import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColumnHeader } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { VideoColumn } from '@/components/RemoteInteraction';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';

import { AudioVideoSourceSelect } from './AudioVideoSourceSelect';
import {
  ToggleCameraButton,
  ToggleMicButton,
  ToggleSettingsButton,
} from './Buttons';
import { PreviewVideo } from './WaitingRoom/PreviewVideo';

export const VideoAndControls = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <VideoColumn flex={2} variant="light">
      {/* Header visible only on desktop */}
      <ColumnHeader title={t('pages.room.settings')} />

      <VideoWrapper data-testid="video-wrapper">
        <PreviewVideo />

        {/* These controls will not be visible for mobile landscape */}
        <ControlsWrapper data-testid="non-mobile-landscape-controls">
          <HardwareButtonsWrapper>
            <ToggleMicButton size={isMobile ? 'medium' : 'large'} />
            <ToggleCameraButton size={isMobile ? 'medium' : 'large'} />
          </HardwareButtonsWrapper>

          <SettingsButtonWrapper>
            <ToggleSettingsButton size={'medium'} />
          </SettingsButtonWrapper>
        </ControlsWrapper>
      </VideoWrapper>

      {!isMobile && <AudioVideoSourceSelect />}
    </VideoColumn>
  );
};

const VideoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: Number(theme.shape.borderRadius) * 2,
  background: theme.palette.grey[800],

  gap: theme.spacing(2),
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),

  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // This will prevent actual video from growing
    flex: '1 1 0',
    minHeight: 0,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      padding: 0,
    },
}));

const ControlsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    padding: `0 ${theme.spacing(1)}`,
    justifyContent: 'space-between',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const HardwareButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));

const SettingsButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));
