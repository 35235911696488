import { withSentryReactRouterV6Routing } from '@sentry/react';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes as DomRoutes, useLocation } from 'react-router-dom';

import { HomepageLayout, RemoteInteractionLayout } from '@/layouts';
import { Homepage } from '@/pages/Homepage';
import { Room } from '@/pages/Room';

const SentryRoutes = withSentryReactRouterV6Routing(DomRoutes);

export const Routes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <SentryRoutes key={location.pathname} location={location}>
        <Route path="/" element={<HomepageLayout />}>
          <Route index element={<Homepage />} />
        </Route>
        <Route path="room/:roomId" element={<RemoteInteractionLayout />}>
          <Route index element={<Room />} />
        </Route>
      </SentryRoutes>
    </AnimatePresence>
  );
};
