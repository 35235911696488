import { gql } from '@apollo/client';

export const addRemoteInteractionFeedback = gql`
  mutation addRemoteInteractionFeedback(
    $id: ID!
    $attendeeId: ID
    $rating: Int!
    $content: String
  ) {
    addRemoteInteractionFeedback(
      input: {
        id: $id
        attendeeId: $attendeeId
        rating: $rating
        content: $content
      }
    )
  }
`;
