import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColumnHeader } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { VideoColumn } from '@/components/RemoteInteraction';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { CallStatusContentInfoBlock } from '@/views/Interaction/Telephone/CallStatus';

export const CallStatusBlock = () => {
  const { t } = useTranslation();

  return (
    <VideoColumn flex={2} variant="light">
      {/* Header visible only on desktop */}
      <ColumnHeader title={t('pages.room.callStatus')} />

      <CallStatusWrapper data-testid="call-status-wrapper">
        <CallStatusContentInfoBlock />
      </CallStatusWrapper>
    </VideoColumn>
  );
};

const CallStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: Number(theme.shape.borderRadius) * 2,
  background: theme.palette.grey[800],

  gap: theme.spacing(2),
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),

  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // This will prevent actual video from growing
    flex: '1 1 0',
    minHeight: 0,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      padding: 0,
    },
}));
