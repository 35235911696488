import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS, HOMEPAGE_CARD_CONTENT } from '@/config/layout';

type ContentWrapperProps = {
  children: ReactNode;
};

export const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,

  width: HOMEPAGE_CARD_CONTENT.WIDTH,

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      alignItems: 'flex-start',
      width: '100%',
    },
}));
