import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { NotistackProvider } from '@/components/notification/NotistackProvider';
import { InteractionContextProvider } from '@/hooks/useInteraction';

import { NotificationProvider } from './hooks/useNotification';
import { Routes } from './Routes';
import { useWebApiClient } from './services';

export const App = () => {
  const { webApiClient } = useWebApiClient();

  return (
    <NotistackProvider>
      <NotificationProvider>
        <InteractionContextProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ApolloProvider client={webApiClient}>
              <Routes />
            </ApolloProvider>
          </LocalizationProvider>
        </InteractionContextProvider>
      </NotificationProvider>
    </NotistackProvider>
  );
};
