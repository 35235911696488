import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Footer = () => {
  return (
    <Wrapper>
      <Typography variant="body2">
        ysura GmbH • Metzstr. 14b • 81667 München
      </Typography>
      <Typography variant="body2">www.ysura.com</Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  position: 'absolute',
  bottom: 0,
  left: 0,

  width: '100%',

  padding: theme.spacing(3),

  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));
