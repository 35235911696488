import {
  ConsentEventReplayParams,
  ConsentViewerPayload,
  LocalStorageSetAction,
  Media,
  MediaEventReplayParams,
  Page,
  ParticipantRole,
  Room,
  SampleRequestEventReplayParams,
  SampleRequestViewerPayload,
  ScreenSharingEventReplayParams,
} from '@ysura/common';
import { Dispatch, SetStateAction } from 'react';

export const DEFAULT_FN = () => {};
export const DEFAULT_CALLBACK = () => () => {};

export type SetState<T> = Dispatch<SetStateAction<T>>;

/**
 * granted -- the user was prompted and granted permissions
 * denied -- the user was prompted and denied permissions
 * pending -- the user was never prompted
 */
export type PermissionState = 'granted' | 'pending' | 'denied';

export type InteractionMode = 'remote' | 'phone';

type BooleanVoidFunction = (value: boolean) => void;

export type InitializeStateServerArgs = {
  roomId: string;
  interactionStep: 'waiting-room' | 'interaction';
};

export type ParticipantDeviceState = {
  isMicActive: boolean;
  isCameraActive: boolean;
};

export type SharingMedia = {
  media: Media;
  initialPage?: Page;
};

export type UseProcessState = {
  isAnyProcessShared: boolean;
  isFormSubmitting: boolean;
  isMediaShared: boolean;

  sharingConsent: ConsentViewerPayload | null;
  sharingSampleRequest: SampleRequestViewerPayload | null;
  sharingMedia: SharingMedia | null;

  setIsFormSubmitting: SetState<boolean>;
  setSharingConsent: (form: ConsentViewerPayload | null) => void;
  setSharingSampleRequest: (form: SampleRequestViewerPayload | null) => void;
  setSharingMedia: (sharingMedia: SharingMedia | null) => void;

  isSharingInFullScreen: boolean;
  setIsSharingInFullScreen: SetState<boolean>;
};

export type InteractionStepInfo = 'waiting-room' | 'interaction' | 'feedback';

export type UseInteractionState = {
  roomId: string;
  roomCode: string;
  attendee?: InteractionParticipant;
  organizer?: InteractionParticipant;
  coOrganizers: Array<InteractionParticipant>;
  interactionMode: InteractionMode | null;

  setRoomId: LocalStorageSetAction<string>;
  setRoomCode: LocalStorageSetAction<string>;
  setAttendee: SetState<InteractionParticipant | undefined>;
  setOrganizer: SetState<InteractionParticipant | undefined>;
  setCoOrganizers: SetState<Array<InteractionParticipant>>;
  setInteractionMode: SetState<InteractionMode | null>;

  getInteractionStep: (
    roomId: string | null | undefined
  ) => InteractionStepInfo | null;
  createOrUpdateInteractionStep: (
    roomId: string,
    currentStep: InteractionStepInfo
  ) => void;
  clearInteractionStepForRoomId: (roomId: string | null | undefined) => void;

  clearInteractionState: VoidFunction;
};

export type UseVideoState = {
  audioInputDevices: Array<MediaDeviceInfo>;
  audioOutputDevices: Array<MediaDeviceInfo>;
  videoDevices: Array<MediaDeviceInfo>;

  selectedVideoInput: string;
  selectedAudioInput: string;
  selectedAudioOutput: string;

  participantDeviceState: Record<string, ParticipantDeviceState>;

  setVideoDevices: SetState<Array<MediaDeviceInfo>>;
  setAudioInputDevices: SetState<Array<MediaDeviceInfo>>;
  setAudioOutputDevices: SetState<Array<MediaDeviceInfo>>;
  setSelectedVideoInput: FunctionStringCallback;
  setSelectedAudioInput: FunctionStringCallback;
  setSelectedAudioOutput: FunctionStringCallback;

  isMicActive: boolean;
  isCameraActive: boolean;
  isVideoSettingsDialogOpen: boolean;

  isScreenSharingEnable: boolean;
  setIsScreenSharingEnable: SetState<boolean>;

  setIsMicActive: BooleanVoidFunction;
  setIsCameraActive: BooleanVoidFunction;
  setIsVideoSettingsDialogOpen: SetState<boolean>;

  arePermissionsChecked: boolean;
  microphonePermissionState: PermissionState;
  cameraPermissionState: PermissionState;

  setArePermissionsChecked: SetState<boolean>;
  setMicrophonePermissionState: SetState<PermissionState>;
  setCameraPermissionState: SetState<PermissionState>;

  initializeVideo: (attendeeOid: string | undefined) => void;
  handleVideoTokenGenerated: (
    token: string,
    room: Room,
    attendeeOid: string | undefined
  ) => void;
  leaveVideoCall: VoidFunction;

  startScreenSharing: (
    shouldBroadcast?: boolean,
    participantSharingId?: string
  ) => void;
  handleNotifyScreenShareStarted: (attendeeOid: string | undefined) => void;
  stopScreenSharing: VoidFunction;
  isOwnScreenShared: boolean;
  isParticipantsScreenShared: boolean;
  setIsOwnScreenShared: SetState<boolean>;
  setIsParticipantsScreenShared: SetState<boolean>;
};

export type InteractionParticipant = {
  personOid: string;
  role: ParticipantRole;
  displayName: string;
};

export type InitChannelArgs = {
  roomId: string;
  attendee: InteractionParticipant;
  interactionStep: 'waiting-room' | 'interaction';
  onTokenCreated?: (
    token: string,
    room: Room,
    attendeeOid: string | undefined
  ) => void;
  onMediaEventReplay?: (params: MediaEventReplayParams) => void;
  onConsentEventReplay?: (params: ConsentEventReplayParams) => void;
  onScreenSharingEventReplay?: (params: ScreenSharingEventReplayParams) => void;
  onSampleRequestEventReplay?: (params: SampleRequestEventReplayParams) => void;
  hasVideoConnection: boolean;
};

export type ConsentValue = {
  id: string;
  name: string;
  triggeredMessage: boolean;
  valid: boolean;
  value: boolean | string;
};
