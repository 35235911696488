import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentFormPreview,
  ConsentItemData,
  ConsentValue,
  ConsentViewerPayload,
  CountryData,
  Loader,
  SignatureBox,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentCommunicationCollection } from '@/components/RemoteInteraction/ConsentForm';
import { useInteraction } from '@/hooks';

export const ConsentCollection = () => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [signature, setSignature] = useState('');
  const [consentValues, setConsentValues] = useState<ConsentValue>({});
  const [defaultCountry, setDefaultCountry] = useState<CountryData | undefined>(
    undefined
  );
  const [consentItemsCommunicationData, setConsentItemsCommunicationData] =
    useState<ConsentItemData[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const {
    broadcastConsentFormValueChange,
    broadcastConsentFormSubmit,
    sharingConsent,
    setIsFormSubmitting,
  } = useInteraction();

  const handleFormSubmit = () => {
    if (sharingConsent?.state.step === 'preview') {
      const event: ConsentViewerPayload = {
        id: sharingConsent?.id ?? '',
        step: 'preview',
        consentType: sharingConsent?.consentType,
        state: {
          step: 'preview',
          title: sharingConsent.state.title,
          content: sharingConsent.state.content,
        },
      };

      setIsFormSubmitting?.(true);
      broadcastConsentFormSubmit?.(event);
      setIsSubmitButtonDisabled(true);
    }
  };

  const broadcastChanges = (url: string, values: ConsentValue) => {
    if (sharingConsent?.state.step === 'collect') {
      const event: ConsentViewerPayload = {
        id: sharingConsent?.id ?? '',
        step: 'collect',
        consentType: sharingConsent.consentType,
        state: {
          step: 'collect',
          title: sharingConsent.state.title,
          staticText: '',
          consentItems: values,
          consentCommunicationData: consentItemsCommunicationData,
          signature: url,
        },
      };
      broadcastConsentFormValueChange?.(event);
    }
  };

  const handleSignature = (url: string) => {
    setSignature(url);
    broadcastChanges(url, consentValues);
  };

  const handleConsentValueChanged = (consentValues: ConsentValue) => {
    setConsentValues(consentValues);
    broadcastChanges(signature, consentValues);
  };

  useEffect(() => {
    if (sharingConsent?.state?.step === 'collect') {
      const state = sharingConsent.state;
      setSignature(state.signature);
      setDescription(state.staticText);
      setConsentValues(state.consentItems);
      setConsentItemsCommunicationData(state.consentCommunicationData);
      setDefaultCountry(sharingConsent?.validation?.defaultCountryPhone);
    }
  }, [sharingConsent]);

  if (sharingConsent) {
    return (
      <Wrapper data-testid="consent-collection-card">
        <Header>
          <Typography variant="h6">
            {sharingConsent?.state?.title ?? ''}
          </Typography>
          {sharingConsent?.state?.step === 'preview' && (
            <Button
              variant="contained"
              disabled={isSubmitButtonDisabled}
              onClick={handleFormSubmit}
            >
              {t('components.common.submit')}
            </Button>
          )}
        </Header>

        {sharingConsent?.state?.step === 'collect' && (
          <Content data-testid="ConsentForm">
            <FormWrapper>
              <StyleTypography variant="body1">{description}</StyleTypography>
              <StyleGrid container>
                <ConsentItemGrid item xs={12}>
                  <ConsentCommunicationCollection
                    consentItemsCommunicationData={
                      consentItemsCommunicationData
                    }
                    consentValues={consentValues}
                    defaultCountry={defaultCountry}
                    setConsentValues={handleConsentValueChanged}
                    setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
                  />
                </ConsentItemGrid>
              </StyleGrid>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <SignatureBox
                    isClearable={true}
                    signature={signature}
                    signatureLabel={t('components.signatureBox.signature')}
                    clearLabel={t('components.signatureBox.clear')}
                    errorLabel={t('components.signatureBox.signatureNotValid')}
                    onChangeSignature={handleSignature}
                  />
                </Grid>
              </Grid>
            </FormWrapper>
          </Content>
        )}

        {sharingConsent?.state?.step === 'preview' && (
          <ConsentFormPreview state={sharingConsent.state} />
        )}
      </Wrapper>
    );
  }

  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflowY: 'auto',
}));

const LoaderWrapper = styled(Box)({
  height: '100%',
  display: 'grid',
  placeContent: 'center',
});

const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const StyleGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.only('xs')]: {
    gap: theme.spacing(2),
  },
}));

const ConsentItemGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyleTypography = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));
