import { styled } from '@mui/material/styles';
import { PageOverlay } from '@ysura/common';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type WaitingRoomAnimateProps = {
  children: ReactNode;
};

export const WaitingRoomAnimate = ({ children }: WaitingRoomAnimateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <PageOverlay
        key="waiting-room-overlay"
        animate="exit"
        overlayText={t('components.transition.joiningCall')}
      />

      <AnimateBox
        key="waiting-room"
        exit={{
          opacity: 0,
          transition: { duration: 0.4, ease: 'easeOut', delay: 0.4 },
        }}
      >
        {children}
      </AnimateBox>
    </>
  );
};

const AnimateBox = styled(motion.div)({
  width: '100%',
  height: '100%',
});
