import {
  Media,
  PageContainer,
  SampleRequestViewerPayload,
  SimpleDialog,
  VideoControlsWrapper,
} from '@ysura/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction, useNotification } from '@/hooks';
import {
  ConsentActionManager,
  InteractionHeader,
} from '@/views/Interaction/Common';

import { VideoSettingsDialog } from '../VideoSettingsDialog';
import { InteractionContent } from './InteractionContent';
import { VideoControlsMobile } from './VideoControlsMobile';

type InteractionProps = {
  handleLeaveInteraction: VoidFunction;
};

export const Interaction = ({ handleLeaveInteraction }: InteractionProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { toast } = useNotification();
  const { t } = useTranslation();

  const {
    isFormSubmitting,
    setIsFormSubmitting,
    // Sample Request
    setSharingSampleRequest,
    onSampleRequestOpened,
    onSampleRequestClosed,
    onSampleRequestValueChanged,
    // Media
    setSharingMedia,
    onMediaOpened,
    onMediaClosed,
    onOrganizerTerminateSession,
  } = useInteraction();

  const handleSessionTerminated = useCallback(() => {
    toast?.({ message: t('notifications.sessionEnded'), type: 'info' });

    handleLeaveInteraction();
  }, [handleLeaveInteraction, t, toast]);

  useEffect(() => {
    const handleShowSampleRequest = (data: SampleRequestViewerPayload) => {
      setSharingSampleRequest(data);
    };

    const handleHideSampleRequest = () => {
      if (isFormSubmitting) {
        toast?.({
          message: t('notifications.sampleRequestFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('notifications.sampleRequestFormCancelled'),
          type: 'warning',
        });
      }

      setIsFormSubmitting(false);
      setSharingSampleRequest(null);
    };

    const unsubscribeOnSampleRequestOpen = onSampleRequestOpened?.(
      handleShowSampleRequest
    );
    const unsubscribeOnSampleRequestClose = onSampleRequestClosed?.(
      handleHideSampleRequest
    );
    const unsubscribeOnSampleRequestChange = onSampleRequestValueChanged?.(
      handleShowSampleRequest
    );

    return () => {
      if (unsubscribeOnSampleRequestOpen) {
        unsubscribeOnSampleRequestOpen();
      }
      if (unsubscribeOnSampleRequestClose) {
        unsubscribeOnSampleRequestClose();
      }
      if (unsubscribeOnSampleRequestChange) {
        unsubscribeOnSampleRequestChange();
      }
    };
  }, [
    onSampleRequestOpened,
    onSampleRequestClosed,
    setSharingSampleRequest,
    onSampleRequestValueChanged,
    isFormSubmitting,
    setIsFormSubmitting,
    toast,
    t,
  ]);

  useEffect(() => {
    const handleShowMedia = (data: Media) => {
      setSharingMedia({ media: data });
    };

    const handleHideMedia = () => {
      setSharingMedia(null);
    };

    const unsubscribeOnMediaOpen = onMediaOpened?.(handleShowMedia);
    const unsubscribeOnMediaClose = onMediaClosed?.(handleHideMedia);

    return () => {
      if (unsubscribeOnMediaOpen) {
        unsubscribeOnMediaOpen();
      }
      if (unsubscribeOnMediaClose) {
        unsubscribeOnMediaClose();
      }
    };
  }, [onMediaClosed, onMediaOpened, setSharingMedia]);

  useEffect(() => {
    // handles the session terminating. Reasons for this can be:
    // - Organizer closes the room
    // - Organizer forcefully removes the attendee from the room
    // - The session expires
    const unsubscribeOnOrganizerTerminateSession =
      onOrganizerTerminateSession?.(handleSessionTerminated);

    return () => {
      unsubscribeOnOrganizerTerminateSession?.();
    };
  }, [handleSessionTerminated, onOrganizerTerminateSession]);

  return (
    <>
      <PageContainer>
        {/* Room header is only visible on desktop */}
        <InteractionHeader />

        {/* This exact controls component is only visible on mobile landscape */}
        <VideoControlsWrapper>
          <VideoControlsMobile
            openLeaveInteractionDialog={() => setIsDialogOpen(true)}
          />
        </VideoControlsWrapper>

        {/* Content is always visible */}
        <InteractionContent
          openLeaveInteractionDialog={() => setIsDialogOpen(true)}
        />
      </PageContainer>

      <VideoSettingsDialog />

      <SimpleDialog
        isOpen={isDialogOpen}
        testId={'end-interaction-dialog'}
        title={t('pages.room.leaveQuestion')}
        closeDialogText={t('pages.room.leave')}
        closeDialog={handleLeaveInteraction}
        cancelDialogText={t('components.common.cancel')}
        cancelDialog={() => setIsDialogOpen(false)}
      />

      <ConsentActionManager />
    </>
  );
};
