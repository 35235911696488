import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';

type LandscapeVideoControlsWrapperProps = {
  children: ReactNode;
};

export const LandscapeVideoControlsWrapper = ({
  children,
}: LandscapeVideoControlsWrapperProps) => {
  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  if (!isSmallScreenLandscape) {
    return null;
  }

  return (
    <Wrapper data-testid="landscape-video-controls-wrapper">{children}</Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',

  height: '100%',
  width: REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE,

  background: theme.palette.grey[800],
}));
