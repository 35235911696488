import {
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

import { AudioVideoSourceSelect } from './AudioVideoSourceSelect';

export const VideoSettingsDialog = () => {
  const { t } = useTranslation();
  const { isVideoSettingsDialogOpen, setIsVideoSettingsDialogOpen } =
    useInteraction();

  return (
    <Dialog
      fullWidth
      open={isVideoSettingsDialogOpen}
      maxWidth={'sm'}
      data-testid="settings-dialog"
    >
      <DialogTitle>
        <Typography variant="h5">{t('pages.room.settings')}</Typography>
        <Button
          variant="contained"
          onClick={() => setIsVideoSettingsDialogOpen?.(false)}
        >
          {t('pages.room.audioVideoSettings.done')}
        </Button>
      </DialogTitle>
      <DialogContent>
        <AudioVideoSourceSelect isInDialog />
      </DialogContent>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)({
  '.MuiDialog-paperWidthXl, .MuiDialog-paperWidthMd': {
    height: '100%',
  },
});

const DialogTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  height: '100%',
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
