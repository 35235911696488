import { Person } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InteractionItemsColumn } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

import { PermissionMessages } from '../../Remote/WaitingRoom/PermissionMessages';

type WaitingRoomCallInfoProps = {
  handleStartInteraction: VoidFunction;
  isButtonDisabled?: boolean;
  checkPermission?: boolean;
};

export const WaitingRoomCallInfo = ({
  handleStartInteraction,
  isButtonDisabled = false,
  checkPermission = true,
}: WaitingRoomCallInfoProps) => {
  const { t } = useTranslation();
  const { organizer, coOrganizers, getParticipantInteractionStep } =
    useInteraction();

  const getStatusMessage = (
    interactionStep: 'waiting-room' | 'interaction' | undefined | null,
    personFullName: string
  ) => {
    if (interactionStep === 'waiting-room') {
      return t('pages.room.personWaitingRoomStatus.inWaitingRoom', {
        personName: personFullName,
      });
    } else if (interactionStep === 'interaction') {
      return t('pages.room.personWaitingRoomStatus.inCall', {
        personName: personFullName,
      });
    }

    return t('pages.room.personWaitingRoomStatus.notInCall', {
      personName: personFullName,
    });
  };

  return (
    <InteractionItemsColumn flex={1} isSharing={false}>
      <ContentWrapper>
        <Button
          variant="contained"
          disabled={isButtonDisabled}
          onClick={handleStartInteraction}
        >
          {t('pages.room.joinCall')}
        </Button>
        <SectionWrapper>
          <LineBox>
            <Typography variant="caption">
              {t('pages.room.organizer')}
            </Typography>
          </LineBox>
          <LineBox key={organizer?.personOid ?? ''}>
            <Person fontSize="small" />
            <Typography variant="subtitle2">
              {getStatusMessage(
                getParticipantInteractionStep?.(organizer?.personOid ?? ''),
                organizer?.displayName ?? ''
              )}
            </Typography>
          </LineBox>

          {!!coOrganizers.length && (
            <>
              <LineBox>
                <Typography variant="caption">
                  {t('pages.room.coOrganizers')}
                </Typography>
              </LineBox>

              {coOrganizers?.map((coOrganizer) => {
                return (
                  <LineBox key={coOrganizer?.personOid ?? ''}>
                    <Person fontSize="small" />
                    <Typography variant="subtitle2">
                      {getStatusMessage(
                        getParticipantInteractionStep?.(
                          coOrganizer?.personOid ?? ''
                        ),
                        coOrganizer?.displayName ?? ''
                      )}
                    </Typography>
                  </LineBox>
                );
              })}
            </>
          )}
        </SectionWrapper>

        {checkPermission && <PermissionMessages />}
      </ContentWrapper>
    </InteractionItemsColumn>
  );
};

const ContentWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(2),
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  gap: theme.spacing(2),
}));

const LineBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));
