import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CUSTOM_BREAKPOINTS } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useInteraction } from '@/hooks';

import { FeedbackStep } from '../Feedback';

type FeedbackThankYouProps = {
  feedbackStep: FeedbackStep;
};

export const FeedbackThankYou = ({ feedbackStep }: FeedbackThankYouProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clearInteractionState } = useInteraction();

  const handleBackToHomepage = () => {
    clearInteractionState();
    navigate('/');
  };

  return (
    <Wrapper data-testid="feedback-thank-you-wrapper">
      <Typography variant="h5">
        {feedbackStep === 'thankAttending' &&
          t('pages.feedback.thankYouForAttending')}
        {feedbackStep === 'thankFeedback' &&
          t('pages.feedback.thankYouFeedback')}
      </Typography>
      <BodyTypography variant="body2">
        {feedbackStep === 'thankAttending' && t('pages.feedback.seeYou')}
        {feedbackStep === 'thankFeedback' &&
          t('pages.feedback.yourResponseWasSubmitted')}
      </BodyTypography>
      <StyledButton
        variant="outlined"
        size="large"
        onClick={handleBackToHomepage}
      >
        {t('pages.feedback.backToAccessPage')}
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      justifyContent: 'center',
    },
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
});
