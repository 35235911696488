import {
  Callback,
  ConsentEventReplayParams,
  HeartbeatCallbackParams,
  MediaEventReplayParams,
  Participant,
  ReactProps,
  SampleRequestEventReplayParams,
  ScreenSharingEventReplayParams,
} from '@ysura/common';
import { FC, useCallback } from 'react';

import {
  InteractionStateContextProvider,
  useInteractionState,
} from './useInteractionState';
import {
  InitializeStateServerArgs,
  UseInteractionState,
  UseProcessState,
  UseVideoState,
} from './useInteractionTypes';
import {
  ProcessStateContextProvider,
  useProcessState,
} from './useProcessState';
import {
  StateServerContextProvider,
  UseStateServer,
  useStateServer,
} from './useStateServer';
import { useVideoState, VideoStateContextProvider } from './useVideoState';

export type UseInteraction = Omit<
  UseVideoState,
  | 'handleVideoTokenGenerated'
  | 'leaveVideoCall'
  | 'setIsOwnScreenShared'
  | 'setIsParticipantsScreenShared'
  | 'handleNotifyScreenShareStarted'
> &
  Pick<
    UseStateServer,
    | 'isStateServerInitialized'
    | 'totalPeopleInRoom'
    | 'onAttendeeReadyOrganizerNotify'
    | 'onOrganizerTerminateSession'
    | 'onMediaStateChanged'
    | 'onPointerShown'
    | 'onMediaClosed'
    | 'onMediaOpened'
    | 'broadcastMediaAttendeeReady'
    | 'broadcastPointerShow'
    | 'broadcastWaitingRoomToInteraction'
    | 'broadcastSampleRequestSubmit'
    | 'broadcastSampleRequestValueChange'
    | 'onSampleRequestValueChanged'
    | 'onSampleRequestOpened'
    | 'onSampleRequestClosed'
    | 'broadcastConsentFormValueChange'
    | 'broadcastConsentFormSubmit'
    | 'onConsentFormValueChanged'
    | 'onConsentFormOpened'
    | 'onConsentClosed'
    | 'onOrganizerConsentRejected'
    | 'onOrganizerConsentRevoked'
    | 'broadcastRejectConsentConfirm'
    | 'broadcastRevokeConsentConfirm'
    | 'broadcastConsentClose'
  > &
  UseProcessState &
  Omit<UseInteractionState, 'clearInteractionStepForRoomId'> & {
    initializeStateServer: ({ roomId }: InitializeStateServerArgs) => void;
    heartbeat: VoidFunction;
    leaveInteraction: VoidFunction;

    isAnyContentShared: boolean;

    onScreenShareStarted: () => Callback;

    getParticipantInteractionStep: (
      participantID: string
    ) => 'waiting-room' | 'interaction' | undefined;
  };

export const useInteraction = (): UseInteraction => {
  const {
    roomInfo,
    setRoomInfo,
    initChannel,
    doHeartbeat,
    isStateServerInitialized,
    closeStateServerConnection,
    totalPeopleInRoom,

    onAttendeeReadyOrganizerNotify,
    onOrganizerTerminateSession,
    onMediaStateChanged,
    onPointerShown,
    onMediaClosed,
    onMediaOpened,

    broadcastMediaAttendeeReady,
    broadcastPointerShow,
    broadcastWaitingRoomToInteraction,

    broadcastSampleRequestSubmit,
    broadcastSampleRequestValueChange,
    onSampleRequestValueChanged,
    onSampleRequestOpened,
    onSampleRequestClosed,

    broadcastConsentFormValueChange,
    broadcastConsentFormSubmit,
    broadcastConsentClose,
    onConsentFormValueChanged,
    onConsentFormOpened,
    onConsentClosed,
    onOrganizerConsentRejected,
    onOrganizerConsentRevoked,
    broadcastRejectConsentConfirm,
    broadcastRevokeConsentConfirm,
    onScreenShareStarted: onScreenShareStartedInternal,
  } = useStateServer();
  const {
    initializeVideo,
    handleVideoTokenGenerated,
    leaveVideoCall,
    isMicActive,
    setIsMicActive,
    isCameraActive,
    setIsCameraActive,
    isOwnScreenShared,
    isParticipantsScreenShared,
    arePermissionsChecked,
    selectedAudioInput,
    setSelectedAudioOutput,
    selectedVideoInput,
    audioOutputDevices,
    selectedAudioOutput,
    cameraPermissionState,
    microphonePermissionState,
    videoDevices,
    audioInputDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    setVideoDevices,
    setSelectedAudioInput,
    setSelectedVideoInput,
    setCameraPermissionState,
    setMicrophonePermissionState,
    setArePermissionsChecked,
    isScreenSharingEnable,
    setIsScreenSharingEnable,
    isVideoSettingsDialogOpen,
    setIsVideoSettingsDialogOpen,
    participantDeviceState,
    startScreenSharing: startScreenSharingInternal,
    stopScreenSharing,
    handleNotifyScreenShareStarted,
  } = useVideoState();
  const processState = useProcessState();
  const interactionState = useInteractionState();

  /****************************
   * Event Replay             *
   ****************************/

  const handleMediaEventReplay = useCallback(
    (mediaReplay: MediaEventReplayParams) => {
      processState.setIsSharingInFullScreen(true);

      processState.setSharingMedia({
        media: {
          contentDocument: {
            downloadUrl:
              mediaReplay.media.latestVersion?.document?.contentLink ?? '',
            mediaType:
              mediaReplay.media.latestVersion?.document?.mediaType ?? '',
          },
        },
        initialPage: mediaReplay.initialPage,
      });
    },
    [processState]
  );

  const handleConsentEventReplay = useCallback(
    ({ state }: ConsentEventReplayParams) => {
      processState.setSharingConsent(state);
    },
    [processState]
  );

  const handleScreenSharingEventReplay = useCallback(
    ({ state }: ScreenSharingEventReplayParams) => {
      const currentPersonOid = interactionState.attendee?.personOid;
      if (currentPersonOid && state.participantSharingId !== currentPersonOid) {
        handleNotifyScreenShareStarted(currentPersonOid);
      }
    },
    [handleNotifyScreenShareStarted, interactionState.attendee?.personOid]
  );

  const handleSampleRequestEventReplay = useCallback(
    ({ state }: SampleRequestEventReplayParams) => {
      processState.setSharingSampleRequest(state);
    },
    [processState]
  );

  /*****************************
   * Screen Sharing            *
   *****************************/

  const startScreenSharing = useCallback(
    (shouldBroadcast?: boolean) => {
      const attendee = interactionState.attendee;
      startScreenSharingInternal(shouldBroadcast, attendee?.personOid);
    },
    [interactionState.attendee, startScreenSharingInternal]
  );

  const onScreenShareStarted = useCallback(() => {
    return onScreenShareStartedInternal(() =>
      handleNotifyScreenShareStarted(interactionState.attendee?.personOid)
    );
  }, [
    handleNotifyScreenShareStarted,
    interactionState.attendee?.personOid,
    onScreenShareStartedInternal,
  ]);

  /*****************************
   * Management                *
   *****************************/

  const initializeStateServer = useCallback(
    ({ roomId, interactionStep }: InitializeStateServerArgs) => {
      const attendee = interactionState.attendee;
      if (attendee) {
        if (interactionState.interactionMode === 'remote') {
          initChannel({
            roomId,
            attendee,
            interactionStep,
            hasVideoConnection: true,
            onTokenCreated: handleVideoTokenGenerated,
            onMediaEventReplay: handleMediaEventReplay,
            onConsentEventReplay: handleConsentEventReplay,
            onSampleRequestEventReplay: handleSampleRequestEventReplay,
          });
        } else if (interactionState.interactionMode === 'phone') {
          initChannel({
            roomId,
            attendee,
            interactionStep,
            hasVideoConnection: false,
            onMediaEventReplay: handleMediaEventReplay,
            onConsentEventReplay: handleConsentEventReplay,
            onScreenSharingEventReplay: handleScreenSharingEventReplay,
            onSampleRequestEventReplay: handleSampleRequestEventReplay,
          });
        } else {
          console.warn(
            `State server cannot be used in this activity type: ${interactionState.interactionMode}`
          );
        }
      }
    },
    [
      handleConsentEventReplay,
      handleMediaEventReplay,
      handleSampleRequestEventReplay,
      handleScreenSharingEventReplay,
      handleVideoTokenGenerated,
      initChannel,
      interactionState.attendee,
      interactionState.interactionMode,
    ]
  );

  const heartbeatCallback = useCallback(
    ({ room }: HeartbeatCallbackParams) => {
      if (room) {
        setRoomInfo(room);
      }
    },
    [setRoomInfo]
  );

  const heartbeat = useCallback(() => {
    const interactionStep = interactionState.getInteractionStep(
      interactionState.roomId
    );

    if (
      isStateServerInitialized &&
      interactionState.attendee?.personOid &&
      interactionState.attendee?.role &&
      (interactionStep === 'interaction' || interactionStep === 'waiting-room')
    ) {
      const participant: Participant = {
        id: interactionState.attendee.personOid,
        displayName: interactionState.attendee?.displayName,
        role: interactionState.attendee.role,
        step: interactionStep,
      };

      doHeartbeat(participant, heartbeatCallback);
    }
  }, [
    doHeartbeat,
    heartbeatCallback,
    interactionState,
    isStateServerInitialized,
  ]);

  const getParticipantInteractionStep = useCallback(
    (participantID: string) => {
      return roomInfo?.participants?.[participantID]?.step;
    },
    [roomInfo]
  );

  const leaveInteraction = useCallback(() => {
    // reset interaction mode values
    processState.setSharingConsent(null);
    processState.setSharingMedia(null);
    leaveVideoCall();

    if (isStateServerInitialized) {
      closeStateServerConnection();
    }
  }, [
    closeStateServerConnection,
    isStateServerInitialized,
    leaveVideoCall,
    processState,
  ]);

  return {
    ...processState,
    ...interactionState,
    initializeStateServer,
    isStateServerInitialized,
    leaveInteraction,
    getParticipantInteractionStep,
    totalPeopleInRoom,
    heartbeat,

    onAttendeeReadyOrganizerNotify,
    onOrganizerTerminateSession,
    onMediaStateChanged,
    onPointerShown,
    onMediaClosed,
    onMediaOpened,

    broadcastMediaAttendeeReady,
    broadcastPointerShow,
    broadcastWaitingRoomToInteraction,

    broadcastSampleRequestSubmit,
    broadcastSampleRequestValueChange,
    onSampleRequestValueChanged,
    onSampleRequestOpened,
    onSampleRequestClosed,

    broadcastConsentFormValueChange,
    broadcastConsentFormSubmit,
    broadcastConsentClose,
    onConsentFormValueChanged,
    onConsentFormOpened,
    onConsentClosed,
    onOrganizerConsentRejected,
    onOrganizerConsentRevoked,
    broadcastRejectConsentConfirm,
    broadcastRevokeConsentConfirm,

    isAnyContentShared:
      processState.isAnyProcessShared || isParticipantsScreenShared,
    initializeVideo,
    isParticipantsScreenShared,
    isOwnScreenShared,
    isMicActive,
    setIsMicActive,
    isCameraActive,
    setIsCameraActive,
    arePermissionsChecked,
    selectedAudioInput,
    selectedAudioOutput,
    selectedVideoInput,
    cameraPermissionState,
    microphonePermissionState,
    videoDevices,
    audioInputDevices,
    setAudioInputDevices,
    setAudioOutputDevices,
    setVideoDevices,
    audioOutputDevices,
    setSelectedAudioInput,
    setSelectedAudioOutput,
    setSelectedVideoInput,
    setCameraPermissionState,
    setMicrophonePermissionState,
    setArePermissionsChecked,
    isScreenSharingEnable,
    setIsScreenSharingEnable,
    isVideoSettingsDialogOpen,
    setIsVideoSettingsDialogOpen,
    participantDeviceState,
    startScreenSharing,
    stopScreenSharing,
    onScreenShareStarted,
  };
};

export const InteractionContextProvider: FC<ReactProps> = ({ children }) => {
  return (
    <InteractionStateContextProvider>
      <StateServerContextProvider>
        <ProcessStateContextProvider>
          <VideoStateContextProvider>{children}</VideoStateContextProvider>
        </ProcessStateContextProvider>
      </StateServerContextProvider>
    </InteractionStateContextProvider>
  );
};
