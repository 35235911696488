import * as Sentry from '@sentry/react';
import {
  getVideoElementStatus,
  ReactProps,
  Room,
  useLocalStorage,
} from '@ysura/common';
import { ExceptionEvent, OpenVidu, Publisher, Stream } from 'openvidu-browser';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SYSTEM } from '@/config';
import { useNotification } from '@/hooks';

import {
  DEFAULT_FN,
  ParticipantDeviceState,
  PermissionState,
  UseVideoState,
} from './useInteractionTypes';
import { useStateServer } from './useStateServer';

export const useVideoStateInitialState: UseVideoState = {
  audioInputDevices: [],
  audioOutputDevices: [],
  videoDevices: [],

  selectedVideoInput: '',
  selectedAudioInput: '',
  selectedAudioOutput: '',

  isMicActive: true,
  isCameraActive: true,
  isVideoSettingsDialogOpen: false,

  isScreenSharingEnable: false,
  isOwnScreenShared: false,
  isParticipantsScreenShared: false,

  arePermissionsChecked: false,
  microphonePermissionState: 'denied',
  cameraPermissionState: 'denied',

  participantDeviceState: {},

  handleVideoTokenGenerated: DEFAULT_FN,

  initializeVideo: DEFAULT_FN,
  setVideoDevices: DEFAULT_FN,
  setAudioInputDevices: DEFAULT_FN,
  setAudioOutputDevices: DEFAULT_FN,
  setSelectedVideoInput: DEFAULT_FN,
  setSelectedAudioInput: DEFAULT_FN,
  setSelectedAudioOutput: DEFAULT_FN,
  setIsScreenSharingEnable: DEFAULT_FN,
  setIsMicActive: DEFAULT_FN,
  setIsCameraActive: DEFAULT_FN,
  setIsVideoSettingsDialogOpen: DEFAULT_FN,
  setArePermissionsChecked: DEFAULT_FN,
  setMicrophonePermissionState: DEFAULT_FN,
  setCameraPermissionState: DEFAULT_FN,
  leaveVideoCall: DEFAULT_FN,
  startScreenSharing: DEFAULT_FN,
  handleNotifyScreenShareStarted: DEFAULT_FN,
  stopScreenSharing: DEFAULT_FN,
  setIsOwnScreenShared: DEFAULT_FN,
  setIsParticipantsScreenShared: DEFAULT_FN,
};

const VideoContext = createContext(useVideoStateInitialState);

export const VideoStateContextProvider: FC<ReactProps> = (props) => {
  const [audioInputDevices, setAudioInputDevices] = useState<
    Array<MediaDeviceInfo>
  >([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<
    Array<MediaDeviceInfo>
  >([]);
  const [videoDevices, setVideoDevices] = useState<Array<MediaDeviceInfo>>([]);

  const [arePermissionsChecked, setArePermissionsChecked] = useState(false);
  const [microphonePermissionState, setMicrophonePermissionState] =
    useState<PermissionState>('denied');
  const [cameraPermissionState, setCameraPermissionState] =
    useState<PermissionState>('denied');

  const [selectedVideoInput, setSelectedVideoInputInternal] = useLocalStorage(
    'selectedVideoInput',
    ''
  );
  const [selectedAudioInput, setSelectedAudioInputInternal] = useLocalStorage(
    'selectedAudioInput',
    ''
  );
  const [selectedAudioOutput, setSelectedAudioOutput] = useLocalStorage(
    'selectedAudioOutput',
    ''
  );
  const [isMicActive, setIsMicActiveInternal] = useLocalStorage(
    'isMicActive',
    true
  );

  const [isCameraActive, setIsCameraActiveInternal] = useLocalStorage(
    'isCameraActive',
    true
  );

  const [isScreenSharingEnable, setIsScreenSharingEnable] = useState(
    useVideoStateInitialState.isScreenSharingEnable
  );
  const [isOwnScreenShared, setIsOwnScreenShared] = useState(false);
  const [isParticipantsScreenShared, setIsParticipantsScreenShared] =
    useState(false);

  const [isVideoSettingsDialogOpen, setIsVideoSettingsDialogOpen] =
    useState(false);

  const [participantDeviceState, setParticipantDeviceState] = useState<
    Record<string, ParticipantDeviceState>
  >({});

  // OpenVidu
  const openVidu = useRef<OpenVidu | null>(null);
  const publisher = useRef<Publisher | null>(null);
  const [isVideoSessionInitialized, setIsVideoSessionInitialized] =
    useState<boolean>(false);
  const [isVideoSessionConnected, setIsVideoSessionConnected] =
    useState<boolean>(false);
  const [attendeeToken, setAttendeeToken] = useState<string | undefined>('');

  // ScreenShare
  const ovScreenShare = useRef<OpenVidu | null>(null);
  const screenSharePublisher = useRef<Publisher | null>(null);

  const { t } = useTranslation();
  const { toast } = useNotification();

  /*****************************
   * ComChannel values & methods *
   *****************************/
  const {
    startScreenShare: getScreenShareToken,
    roomInfo: room,
    stopScreenShare: stopScreenShareInternal,
  } = useStateServer();

  const setDeviceStateForParticipant = useCallback(
    (participantId: string, state: ParticipantDeviceState) => {
      setParticipantDeviceState((prevState) => ({
        ...prevState,
        [participantId]: state,
      }));
    },
    [setParticipantDeviceState]
  );

  const getParticipantIdFromStream = (stream: Stream) => {
    const connectionData = JSON.parse(stream.connection.data);

    return connectionData?.participantId;
  };

  const getVideoContainerId = (participantId: string) =>
    `video-container-${participantId}`;

  /*****************************
   * Video / OpenVidu          *
   *****************************/
  const stopMediaTracks = useCallback((publisher: Publisher) => {
    if (publisher) {
      publisher.stream
        ?.getMediaStream()
        ?.getTracks()
        .forEach((track) => {
          track.stop();
        });
    }
  }, []);

  const cleanUpOvScreenShare = useCallback(async () => {
    if (screenSharePublisher.current) {
      stopMediaTracks(screenSharePublisher.current);
      await ovScreenShare.current?.session?.unpublish(
        screenSharePublisher.current
      );
      screenSharePublisher.current = null;
    }

    ovScreenShare.current?.session?.disconnect();
    ovScreenShare.current = null;
  }, [stopMediaTracks]);

  const cleanUpOV = useCallback(async () => {
    await cleanUpOvScreenShare();

    if (publisher.current) {
      stopMediaTracks(publisher.current);
      await openVidu.current?.session?.unpublish(publisher.current);
      publisher.current = null;
    }

    openVidu.current?.session?.disconnect();
    openVidu.current = null;

    setIsVideoSessionConnected(false);
    setIsVideoSessionInitialized(false);
    setAttendeeToken(undefined);
  }, [cleanUpOvScreenShare, stopMediaTracks]);

  useEffect(() => {
    window.addEventListener('beforeunload', cleanUpOV);

    return () => {
      window.removeEventListener('beforeunload', cleanUpOV);
    };
  }, [cleanUpOV]);

  const isOwnStream = useCallback(
    (stream: Stream, attendeeOid: string | undefined) => {
      const connectionData = JSON.parse(stream.connection.data);

      return (
        connectionData?.participantId === attendeeOid ||
        connectionData?.participantId === `screenshare-${attendeeOid}`
      );
    },
    []
  );

  const handleVideoTokenGenerated = useCallback(
    (token: string, room: Room, attendeeOid: string | undefined) => {
      console.log('handleTokenGenerated:');

      let ov = openVidu.current;

      if (!ov) {
        ov = new OpenVidu();
        SYSTEM.IS_PROD && ov.enableProdMode();
        openVidu.current = ov;
      }

      if (token) {
        setAttendeeToken(token);
      } else {
        console.error('No attendee token found in callback');
      }

      if (!ov.session || !isVideoSessionInitialized) {
        const session = ov.initSession();

        console.log('Initializing OpenVidu Session');

        session.on('streamCreated', (event) => {
          if (isOwnStream(event.stream, attendeeOid)) {
            return;
          }

          const isVideoStream =
            (event.stream.hasVideo && event.stream.typeOfVideo === 'CAMERA') ||
            (!event.stream.hasVideo && event.stream.hasAudio);

          const isScreenShareStream =
            event.stream.hasVideo && event.stream.typeOfVideo === 'SCREEN';

          // Participant started streaming video
          if (isVideoStream) {
            const streamParticipantId = getParticipantIdFromStream(
              event.stream
            );

            // Subscribe to the Stream to receive it. HTML video will be appended
            const subscriber = session.subscribe(
              event.stream,
              getVideoContainerId(streamParticipantId)
            );

            // When the HTML video has been appended to DOM...
            subscriber.on('videoElementCreated', async (createdEvent) => {
              const videoElement = createdEvent.element;

              console.log(
                'Video element created. Status:',
                getVideoElementStatus(videoElement)
              );

              try {
                // First, set playsinline ...
                videoElement.playsInline = true;
                // ... then, play the video again to increase chance of autoplay.
                await videoElement.play();

                console.log(
                  'Video started playing for susbscriber:',
                  getVideoElementStatus(videoElement)
                );
              } catch (e) {
                Sentry.captureException(e, {
                  user: {
                    id: attendeeOid,
                  },
                  extra: {
                    participantId: streamParticipantId,
                    room,
                    videoElementStatus: getVideoElementStatus(videoElement),
                  },
                });

                console.error(
                  'Could not play subscriber video:',
                  e,
                  'Status: ',
                  getVideoElementStatus(videoElement)
                );
              }
            });

            // On every Stream destroyed...
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            session.on('streamDestroyed', (destroyedEvent) => {
              // no-op
            });

            session.on('streamPropertyChanged', (propertyEvent) => {
              if (!isOwnStream(propertyEvent.stream, attendeeOid)) {
                const streamPropertyChangedParticipantId =
                  getParticipantIdFromStream(propertyEvent.stream);

                setDeviceStateForParticipant(
                  streamPropertyChangedParticipantId,
                  {
                    isCameraActive: propertyEvent.stream.videoActive,
                    isMicActive: propertyEvent.stream.audioActive,
                  }
                );
              }
            });

            setDeviceStateForParticipant(streamParticipantId, {
              isCameraActive: event.stream.videoActive,
              isMicActive: event.stream.audioActive,
            });
          }

          // Participant started sharing screen
          else if (isScreenShareStream) {
            console.log('Screen sharing started');

            const screenShareSubscriber = session.subscribe(
              event.stream,
              // ID of the DOM element (see ScreenShareColumn.tsx)
              'participantScreenShare'
            );

            // screen share video session IDs always have the format 'screenshare-<participantId>'
            const screenShareParticipantId = getParticipantIdFromStream(
              screenShareSubscriber.stream
            ).replace('screenshare-', '');
            const displayName =
              room.participants?.[screenShareParticipantId]?.displayName ??
              'Participant';

            screenShareSubscriber.on(
              'videoElementCreated',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              (createdEvent) => {
                setIsParticipantsScreenShared(true);

                toast?.({
                  message: t(
                    'pages.room.screenSharing.participantStartedSharing',
                    {
                      displayName,
                    }
                  ),
                  type: 'info',
                });
              }
            );

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            session.on('streamDestroyed', (destroyedEvent) => {
              setIsParticipantsScreenShared?.(false);

              toast?.({
                message: t(
                  'pages.room.screenSharing.participantStoppedSharing',
                  {
                    displayName,
                  }
                ),
                type: 'info',
              });
            });
          }

          // Organizer started streaming something we cannot handle
          else {
            console.log('Unknown video type');
          }
        });

        // On every asynchronous exception...
        session.on('exception', (exception) => {
          console.warn(exception);
        });

        setIsVideoSessionInitialized(true);
      }
    },
    [
      isOwnStream,
      isVideoSessionInitialized,
      openVidu,
      setDeviceStateForParticipant,
      t,
      toast,
    ]
  );

  const initializeVideo = useCallback(
    (attendeeOid: string | undefined) => {
      if (!openVidu.current) {
        console.log('initializeVideo: OpenVidu not initialized, returning');

        return;
      }

      if (publisher.current) {
        console.log('initializeVideo: Publisher already published, returning');

        return;
      }

      if (!arePermissionsChecked) {
        console.log('initializeVideo: isUserMediaCollected not set, returning');

        return;
      }

      if (!openVidu.current?.session) {
        console.log(
          'initializeVideo: OpenVidu Session not initialized, returning'
        );

        return;
      }

      if (!attendeeToken) {
        console.log('initializeVideo: No attendee token found, returning');

        return;
      }

      if (!isVideoSessionConnected) {
        console.log('Connecting to OpenVidu Session');
        openVidu.current.session
          .connect(attendeeToken)
          .then(() => {
            setIsVideoSessionConnected(true);

            const newPublisher = openVidu.current?.initPublisher(
              getVideoContainerId(attendeeOid ?? ''),
              {
                audioSource: selectedAudioInput,
                videoSource: selectedVideoInput,
                publishAudio:
                  microphonePermissionState === 'granted' && isMicActive,
                publishVideo:
                  cameraPermissionState === 'granted' && isCameraActive,
              }
            );

            if (newPublisher) {
              newPublisher.on('videoElementCreated', async (createdEvent) => {
                createdEvent.element.playsInline = true;
              });

              openVidu.current?.session.publish(newPublisher);
              publisher.current = newPublisher;
            }
          })
          .catch((error) => {
            console.error('Error connecting to session for attendee: ', error);
          });
      }
    },
    [
      arePermissionsChecked,
      attendeeToken,
      isVideoSessionConnected,
      selectedAudioInput,
      selectedVideoInput,
      microphonePermissionState,
      isMicActive,
      cameraPermissionState,
      isCameraActive,
    ]
  );

  const leaveVideoCall = useCallback(() => {
    cleanUpOV();

    setIsVideoSessionConnected(false);
    setIsVideoSessionInitialized(false);

    setIsOwnScreenShared(false);
    setIsParticipantsScreenShared(false);
  }, [cleanUpOV]);

  /*****************************
   * Audio and Video           *
   *****************************/

  const setIsCameraActive = useCallback(
    (value: boolean) => {
      publisher.current?.publishVideo(value);

      setIsCameraActiveInternal(value);
    },
    [setIsCameraActiveInternal]
  );

  const setIsMicActive = useCallback(
    (value: boolean) => {
      publisher.current?.publishAudio(value);

      setIsMicActiveInternal(value);
    },
    [setIsMicActiveInternal]
  );

  const setSelectedVideoInput = useCallback(
    async (value: string) => {
      setSelectedVideoInputInternal(value);

      // only change the video input if we have a publisher
      // (i.e. we are connected to the session and not in the waiting room)
      if (openVidu.current && publisher.current) {
        const mediaStream = await openVidu.current.getUserMedia({
          audioSource: false,
          videoSource: value,
        });

        // Getting the video track from mediaStream
        const videoTrack = mediaStream.getVideoTracks()[0];

        // Replacing the video track
        publisher.current
          .replaceTrack(videoTrack)
          .then(() => {
            console.log('New track has been published');
          })
          .catch((error) => console.error('Error replacing track', error));
      }
    },
    [setSelectedVideoInputInternal]
  );

  const setSelectedAudioInput = useCallback(
    async (value: string) => {
      setSelectedAudioInputInternal(value);

      // only change the audio input if we have a publisher
      // (i.e. we are connected to the session and not in the waiting room)
      if (openVidu.current && publisher.current) {
        const mediaStream = await openVidu.current.getUserMedia({
          audioSource: value,
          videoSource: false,
        });

        // Getting the audio track from mediaStream
        const audioTrack = mediaStream.getAudioTracks()[0];

        // Replacing the audio track
        publisher.current
          .replaceTrack(audioTrack)
          .then(() => {
            console.log('New track has been published');
          })
          .catch((error) => console.error('Error replacing track', error));
      }
    },
    [setSelectedAudioInputInternal]
  );

  /*************************
   *  Screen Sharing       *
   * **********************/
  const onScreenShareTokenCreated = useCallback(
    ({ token }: { token: string }) => {
      const screenShareSession = ovScreenShare.current?.initSession();

      if (!screenShareSession) {
        console.error(
          'Could not initialize screen share session in token callback'
        );

        return;
      }

      // On every asynchronous exception...
      screenShareSession.on('exception', (exception: ExceptionEvent) => {
        console.warn(exception);
      });

      screenShareSession.connect(token).then(() => {
        if (!screenSharePublisher.current) {
          console.error('No screenPublisher found');
        } else {
          screenShareSession.publish(screenSharePublisher.current).then(() => {
            setIsOwnScreenShared(true);

            toast?.({
              message: t('pages.room.screenSharing.startedSharing'),
              type: 'success',
            });
          });
        }
      });
    },
    [t, toast]
  );

  const startScreenSharing = useCallback(
    (shouldBroadcast?: boolean, participantSharingId?: string) => {
      if (isOwnScreenShared) {
        return;
      }

      console.log('Starting screen sharing');

      let ov = ovScreenShare.current;

      if (!ov) {
        ov = new OpenVidu();
        SYSTEM.IS_PROD && ov.enableProdMode();
        ovScreenShare.current = ov;
      }

      ov
        // 'undefined' here means that we do not display the shared screen on the organizer side
        ?.initPublisherAsync(undefined, {
          videoSource: 'screen',
          // Turn off audio for screen sharing to prevent auto-play issues
          publishAudio: false,
          audioSource: false,
        })
        .then((screenPublisher) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          screenPublisher.once('accessAllowed', (_event) => {
            screenPublisher.stream
              .getMediaStream()
              .getVideoTracks()[0]
              .addEventListener('ended', () => {
                console.log('User pressed the "Stop sharing" button');

                screenPublisher.session.unpublish(screenPublisher).then(() => {
                  toast?.({
                    message: t('pages.room.screenSharing.stoppedSharing'),
                    type: 'info',
                  });

                  stopScreenShareInternal();
                  cleanUpOvScreenShare();
                  setIsOwnScreenShared(false);
                });
              });
          });

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          screenPublisher.once('accessDenied', (_event) => {
            toast?.({
              type: 'warning',
              message: t('pages.room.screenSharing.denied'),
            });
          });

          screenSharePublisher.current = screenPublisher;
          getScreenShareToken?.(
            { shouldBroadcast, participantSharingId },
            ({ token }) => onScreenShareTokenCreated({ token })
          );
        })
        .catch((error) => {
          console.error('Error starting screen sharing: ', error);

          toast?.({
            type: 'error',
            message: t('pages.room.screenSharing.error'),
          });
        });
    },
    [
      cleanUpOvScreenShare,
      getScreenShareToken,
      isOwnScreenShared,
      onScreenShareTokenCreated,
      stopScreenShareInternal,
      t,
      toast,
    ]
  );

  const handleNotifyScreenShareStarted = useCallback(
    (attendeeOid?: string) => {
      getScreenShareToken?.({ shouldBroadcast: false }, (token) => {
        let ov = ovScreenShare.current;

        if (!ov) {
          ov = new OpenVidu();
          SYSTEM.IS_PROD && ov.enableProdMode();
          ovScreenShare.current = ov;
        }

        if (token) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          setAttendeeToken(token);
        } else {
          console.error('No attendee token found in callback');
        }

        const screenShareReceiveSession = ov.initSession();

        screenShareReceiveSession.on('exception', (exception) => {
          console.warn(exception);
        });

        screenShareReceiveSession.on('streamCreated', (event) => {
          if (isOwnStream(event.stream, attendeeOid)) {
            return;
          }

          const isScreenShareStream =
            event.stream.hasVideo && event.stream.typeOfVideo === 'SCREEN';

          if (isScreenShareStream) {
            const screenShareSubscriber = screenShareReceiveSession.subscribe(
              event.stream,
              'participantScreenShare',
              {
                subscribeToAudio: false,
              }
            );

            const screenShareParticipantId = getParticipantIdFromStream(
              screenShareSubscriber.stream
            ).replace('screenshare-', '');

            const displayName =
              room?.participants?.[screenShareParticipantId]?.displayName ??
              'Participant';

            screenShareSubscriber.on(
              'videoElementCreated',
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              async (createdEvent) => {
                // We mute the screen share video element and start playing it
                // to prevent the browser from blocking the video element.
                // See also https://developer.chrome.com/blog/autoplay/
                try {
                  createdEvent.element.muted = true;
                  await createdEvent.element.play();
                } catch (e) {
                  console.error(e);
                }

                setIsParticipantsScreenShared(true);

                toast?.({
                  message: t(
                    'pages.room.screenSharing.participantStartedSharing',
                    {
                      displayName,
                    }
                  ),
                  type: 'info',
                });
              }
            );

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            screenShareReceiveSession.on('streamDestroyed', (event) => {
              setIsParticipantsScreenShared?.(false);

              toast?.({
                message: t(
                  'pages.room.screenSharing.participantStoppedSharing',
                  {
                    displayName,
                  }
                ),
                type: 'info',
              });

              cleanUpOvScreenShare();
            });
          } else {
            console.log('Unknown video type');
          }
        });

        if (!screenShareReceiveSession.connection?.connectionId) {
          screenShareReceiveSession
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            .connect(token)
            .then(() => {
              console.log('Screen share receive session connected');
            })
            .catch((error) => {
              console.error(
                'Error connecting to screen sharesession for attendee: ',
                error
              );

              // TODO show error to user
            });
        }
      });
    },
    [
      cleanUpOvScreenShare,
      getScreenShareToken,
      isOwnStream,
      room?.participants,
      t,
      toast,
    ]
  );

  const stopScreenSharing = useCallback(() => {
    if (screenSharePublisher.current) {
      ovScreenShare.current?.session
        .unpublish(screenSharePublisher.current)
        .then(() => {
          screenSharePublisher.current = null;

          toast?.({
            message: t('pages.room.screenSharing.stoppedSharing'),
            type: 'info',
          });

          setIsOwnScreenShared(false);
          stopScreenShareInternal();
          cleanUpOvScreenShare();
        });
    }
  }, [cleanUpOvScreenShare, stopScreenShareInternal, t, toast]);

  const values = useMemo(
    () => ({
      selectedVideoInput,
      selectedAudioInput,
      selectedAudioOutput,
      isMicActive,
      isCameraActive,
      audioInputDevices,
      audioOutputDevices,
      arePermissionsChecked,
      microphonePermissionState,
      cameraPermissionState,
      videoDevices,
      handleVideoTokenGenerated,
      isVideoSettingsDialogOpen,
      initializeVideo,
      startScreenSharing,
      handleNotifyScreenShareStarted,
      stopScreenSharing,
      isOwnScreenShared,
      isParticipantsScreenShared,
      setIsOwnScreenShared,
      setIsParticipantsScreenShared,
      participantDeviceState,

      isScreenSharingEnable,
      setSelectedVideoInput,
      setSelectedAudioInput,
      setSelectedAudioOutput,
      setIsScreenSharingEnable,
      setIsMicActive,
      setIsCameraActive,
      setAudioInputDevices,
      setAudioOutputDevices,
      setVideoDevices,
      setArePermissionsChecked,
      setMicrophonePermissionState,
      setCameraPermissionState,
      setIsVideoSettingsDialogOpen,
      leaveVideoCall,
    }),
    [
      selectedVideoInput,
      selectedAudioInput,
      selectedAudioOutput,
      isMicActive,
      isCameraActive,
      audioInputDevices,
      audioOutputDevices,
      arePermissionsChecked,
      microphonePermissionState,
      cameraPermissionState,
      videoDevices,
      handleVideoTokenGenerated,
      isVideoSettingsDialogOpen,
      initializeVideo,
      startScreenSharing,
      handleNotifyScreenShareStarted,
      stopScreenSharing,
      isOwnScreenShared,
      isParticipantsScreenShared,
      participantDeviceState,
      isScreenSharingEnable,
      setSelectedVideoInput,
      setSelectedAudioInput,
      setSelectedAudioOutput,
      setIsMicActive,
      setIsCameraActive,
      leaveVideoCall,
    ]
  );

  return <VideoContext.Provider value={values} {...props} />;
};

export const useVideoState = () => {
  return useContext(VideoContext);
};
