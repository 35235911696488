import { Image } from '@ysura/common';

import TelephoneCall from '@/assets/telephone/graphic-telephonecall.svg';

export const CallGraphic = () => {
  return (
    <Image
      visibleByDefault
      src={TelephoneCall}
      data-testid="call-graphic"
      objectFit="contain"
    />
  );
};
