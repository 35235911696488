import { SimpleDialog } from '@ysura/common';
import { useTranslation } from 'react-i18next';

type RejectConsentComponentProps = {
  consentName: string;
  onCancelDialog: VoidFunction;
  onConfirmDialog: VoidFunction;
};

export const RejectConsentDialog = ({
  consentName,
  onCancelDialog,
  onConfirmDialog,
}: RejectConsentComponentProps) => {
  const { t } = useTranslation();

  return (
    <SimpleDialog
      isOpen={true}
      testId={'reject-consent-dialog'}
      title={t('components.consentCollection.rejectConsent.title')}
      description={t('components.consentCollection.rejectConsent.description', {
        consentName,
      })}
      closeDialogText={t('components.common.confirm')}
      closeDialog={onConfirmDialog}
      cancelDialogText={t('components.common.cancel')}
      cancelDialog={onCancelDialog}
    />
  );
};
