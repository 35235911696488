import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LandscapeVideoControlsWrapper } from '@/components/RemoteInteraction';

import {
  ToggleCameraButton,
  ToggleMicButton,
  ToggleSettingsButton,
} from '../Buttons';

export const LandscapeVideoControls = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const size = isMobile ? 'medium' : 'large';

  return (
    <LandscapeVideoControlsWrapper>
      <ControlsContainer>
        <ToggleSettingsButton size={size} />
        <HardwareButtonsWrapper>
          <ToggleMicButton size={size} />
          <ToggleCameraButton size={size} />
        </HardwareButtonsWrapper>
      </ControlsContainer>
    </LandscapeVideoControlsWrapper>
  );
};

const ControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  padding: `${theme.spacing(2)} 0`,
}));

const HardwareButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  gap: theme.spacing(2),
}));
