import { useMediaQuery, useTheme } from '@mui/material';
import {
  CUSTOM_BREAKPOINTS,
  Loader,
  Video as VideoComponent,
  VideoContainer,
} from '@ysura/common';
import { useEffect } from 'react';

import { useInteraction } from '@/hooks';

export const Video = () => {
  const {
    attendee,
    organizer,
    coOrganizers,
    isCameraActive,
    isMicActive,
    initializeVideo,
    getParticipantInteractionStep,
    participantDeviceState,
    isAnyContentShared,
    isParticipantsScreenShared,
    isMediaShared,
    isSharingInFullScreen,
  } = useInteraction();

  /* ***************************************
   * Start videos                          *
   *************************************** */
  useEffect(() => {
    if (!organizer?.personOid || !attendee?.personOid) {
      return;
    }

    initializeVideo(attendee.personOid);
  }, [initializeVideo, attendee?.personOid, organizer?.personOid]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLandscapeMode = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  const isMediaOrScreenShared = isMediaShared || isParticipantsScreenShared;
  const cameraDirection =
    isAnyContentShared || (isMobile && !isLandscapeMode)
      ? 'vertical'
      : 'horizontal';
  const showAsThumbnail =
    isMediaOrScreenShared || (isAnyContentShared && isMobile);

  if (!organizer?.personOid || !attendee?.personOid) {
    return <Loader />;
  }

  const organizerVideo = (
    <VideoComponent
      identifier={organizer.personOid}
      currentStep={getParticipantInteractionStep?.(organizer.personOid)}
      name={organizer?.displayName}
      isVideoActive={
        !isSharingInFullScreen &&
        !!participantDeviceState?.[organizer.personOid]?.isCameraActive
      }
      isAudioActive={
        !!participantDeviceState?.[organizer.personOid]?.isMicActive
      }
      showAsThumbnail={showAsThumbnail}
    />
  );
  const coOrganizerVideos = coOrganizers.map((each) => (
    <VideoComponent
      key={each.personOid}
      identifier={each.personOid}
      currentStep={getParticipantInteractionStep?.(each.personOid)}
      name={each.displayName}
      isVideoActive={
        !isAnyContentShared &&
        participantDeviceState?.[each.personOid]?.isCameraActive
      }
      isAudioActive={participantDeviceState?.[each.personOid]?.isMicActive}
      showAsThumbnail={showAsThumbnail}
    />
  ));

  const attendeeVideo = (
    <VideoComponent
      identifier={attendee.personOid}
      currentStep={'interaction'}
      name={attendee?.displayName}
      isVideoActive={isCameraActive}
      isAudioActive={isMicActive}
      showAsThumbnail={showAsThumbnail}
    />
  );

  return (
    <VideoContainer
      topCameras={[organizerVideo, ...coOrganizerVideos]}
      bottomCamera={attendeeVideo}
      showAsThumbnail={showAsThumbnail}
      hideCameraInMobile={showAsThumbnail}
      cameraDirection={cameraDirection}
    />
  );
};
